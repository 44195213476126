// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });


import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    show_wfoe: localStorage.getItem('show_wfoe') || false,
    show_pa: localStorage.getItem('show_pa') || false,
    role: localStorage.getItem('role') || '',
    active_client: localStorage.getItem('active_client') || '',
    fdi_status: localStorage.getItem('fdi_status') || '',
    username: localStorage.getItem('username') || ''
  },
  mutations: {

    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token ){
      state.status = 'success'
      state.token = token
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.role = ''
      state.active_client = ''
      state.fdi_status = ''
      state.username = ''
    },
    set_wfoe(state) {
      state.show_wfoe = true
    },
    clear_wfoe(state) {
      state.show_wfoe = false
    },

    set_pa(state) {
      state.show_pa = true
    },
    clear_pa(state) {
      state.show_pa = false
    },

    set_role(state, role) {
      state.role = role
    },

    set_active_client(state, client) {
      state.active_client = client
    },

    set_fdi_status(state, status) {
      state.fdi_status = status
    },

    set_user_name(state, username) {
      state.username = username
    }
    

  },

  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        console.log('login')
        console.log(user)
        let baseURL = process.env.VUE_APP_BASE_URL
        console.log(baseURL)
        axios({url: baseURL + '/system/api_login', data: user, method: 'POST' })
        .then(resp => {
          console.log(resp.data)
          const token = resp.data.token
          const role = resp.data.role
          const active_client = resp.data.active_client
          const fdi_status = resp.data.fdi_status
          localStorage.setItem('token', token)
          localStorage.setItem('role',role)
          localStorage.setItem('active_client',active_client)
          localStorage.setItem('fdi_status', fdi_status)
          localStorage.setItem('username', user.username)
          axios.defaults.headers.common['Authorization'] = token
          Vue.prototype.$http = axios;
          Vue.prototype.$http.defaults.baseURL = baseURL;
          
          commit('auth_success', token)
          commit('set_role', role)
          commit('set_active_client', active_client)
          commit('set_fdi_status', fdi_status)
          commit('set_user_name', user.username)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('show_wfoe')
          localStorage.removeItem('show_pa')
          localStorage.removeItem('role')
          localStorage.removeItem('active_client')
          localStorage.removeItem('fdi_status')
          localStorage.removeItem('username')
          reject(err)
        })
      })
  },
  // register({commit}, user){
  //   return new Promise((resolve, reject) => {
  //     commit('auth_request')
  //     axios({url: 'http://localhost:3000/register', data: user, method: 'POST' })
  //     .then(resp => {
  //       const token = resp.data.token
  //       const user = resp.data.user
  //       localStorage.setItem('token', token)
  //       axios.defaults.headers.common['Authorization'] = token
  //       commit('auth_success', token, user)
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       commit('auth_error', err)
  //       localStorage.removeItem('token')
  //       reject(err)
  //     })
  //   })
  // },

  logout({commit}){
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      commit('clear_wfoe')
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('show_wfoe')
      localStorage.removeItem('show_pa')
      localStorage.removeItem('role')
      localStorage.removeItem('active_client')
      localStorage.removeItem('fdi_status')
      localStorage.removeItem('username')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },

  show_wfoe({commit}){
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      commit('set_wfoe')
      localStorage.setItem('show_wfoe', true)
      console.log('set_wfoe')
      resolve()
    })
  },

  show_pa({commit}){
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      commit('set_pa')
      localStorage.setItem('show_pa', true)
      resolve()
    })
  },

  switch_client({commit}, client){
    console.log('switch_client...')
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      commit('set_active_client', client)
      localStorage.setItem('active_client', client)
      resolve()
    })
  },

  set_fdi_status({commit}, status){
    console.log('set fdi status...')
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      commit('set_fdi_status', status)
      localStorage.setItem('fdi_status', status)
      resolve()
    })
  },




  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    wfoeShowed: state => !!state.show_wfoe,
    paShowed: state => !!state.show_pa,
    userRole: state => state.role,
    activeClient: state => state.active_client,
    fdiStatus: state => state.fdi_status,
    username: state => state.username,
    token: state => state.token
  }
})