<template>
  <div class="registered_address">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/wfoe/name" class="px-8 pointer" tag="div"
        ><span class=""> Name </span></router-link
      >

      <router-link to="/wfoe/capital" class="px-8 pointer" tag="div"
        ><span class=""> Capital</span></router-link
      >
      <router-link to="/wfoe/business_scope" class="px-8 pointer" tag="div"
        ><span class=""> Business Scope </span></router-link
      >
      <router-link
        to="/wfoe/registered_address"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text">Registered Address </span></router-link
      >
      <router-link to="/wfoe/operational_period" class="px-8 pointer" tag="div"
        ><span class=""> Other Information </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <note-dlg ref="noteDlg" />

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card>
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Registered Address
          </h3>

          <v-spacer></v-spacer>
        </v-card-title>

        <v-form ref="form">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1"> a) </v-col>
                <v-col cols="11"> Office Site Address </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-radio-group v-model="wfoe.address_type"
                  @change="update_wfoe"
                  >
                    <v-radio
                      key="1"
                      label="Virtual address assisted by RSM"
                      value="virtual_address"
                    ></v-radio>
                    <v-radio
                      key="2"
                      label="Actual address provided by yourself"
                      value="actual_address"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-n5">
                  <v-col cols="1">  </v-col>
                  <v-col cols="11">
                   <v-text-field
                   outlined
                   :disabled="wfoe.address_type === 'virtual_address'"
                    v-model="wfoe.registered_address"
                    @change="update_wfoe"
                  ></v-text-field>
                  </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">  </v-col>
                <v-col cols="11"> Contact person </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                  outlined
                  :disabled="wfoe.address_type === 'virtual_address'"
                    v-model="wfoe.contact_person"
                    @change="update_wfoe"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-form>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <router-link to="/wfoe/business_scope" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/wfoe/operational_period" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import NoteDlg from "@/components/NoteDlg.vue";

export default {
  components: { Progressbar, NoteDlg },

  mounted: function () {
    this.$refs.progress1.bk2 = "/todo2.png";
    this.$refs.progress1.txt2 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_wfoe();
  },

  data() {
    return {
      wfoe: {
        id: "",
      },
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_wfoe() {
      console.log("registerd_address");
      let that = this;
      this.$http
        .get("/wfoe/get_wfoe")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.wfoe = response.data.wfoe;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_wfoe() {
      console.log("update_wfoe...");
      if (this.userRole == 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Agent cannot modify client FDI Information!"
            );
            this.reload_wfoe();

            return
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_wfoe();  
        return;
      }
      if (this.$refs["form"]) {
        let formData = new FormData();
        let that = this;

        formData.append("wfoe_id", this.wfoe.id);
        formData.append("address_type", this.wfoe.address_type);
        formData.append("registered_address", this.wfoe.registered_address);
        formData.append("contact_person", this.wfoe.contact_person);
        formData.append("update_flag", "registered_address");

        this.$http
          .post("/wfoe/update_wfoe", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (resp) {
            if (resp.data.status == "success") {
              console.log("SUCCESS!");
              that.reload_wfoe();
            } else {
              that.$EventBus.$emit("toast", resp.data.msg);
            }
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please provide the leasing agreement with the landlords/ or the property ownership certificate for registration. The registered address cannot be shared with other companies, as one address can only be used for one company registration. Please note if the WFOE will not lease a physical office in the beginning stage, our team could assist to search a virtual registration address for registration purpose. Based on our experience, it usually would cost about RMB 5,000 per year and may be adjusted according to market conditions. Our service scope does not cover the virtual address rental fees. The WFOE could choose to make re-location registration if in future appropriate office address is decided.";
      } 
      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>