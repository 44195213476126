<template>
  <div class="dashboard">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>

    <v-row class="ma-4">
      <AddClientDlg v-if="userRole == 'staff'" @add-client="reload_clients" ref="addClientDlg"/>
      <delete-client-dlg
        ref="deleteClientDlg"
        @delete-client="reload_clients"
      />
    </v-row>

    <v-container fluid class="my-4 ml-4 pr-8">
      <v-card v-for="client in clients" :key="client.id">
        <v-row :class="`pa-3 client ${client.display}`">
          <v-col cols="2">
            <div class="caption grey--text">Company Name</div>
            <div>{{ client.company_name }}</div>
          </v-col>
          <v-col cols="2">
            <div class="caption grey--text">Username</div>
            <div>{{ client.username }}</div>
          </v-col>
          <v-col cols="2">
            <div class="caption grey--text">Email</div>
            <div>{{ client.email }}</div>
          </v-col>
          <v-col cols="1">
            <div class="caption grey--text">Create Time</div>
            <div>{{ client.create_time }}</div>
          </v-col>
          <v-col cols="2">
            <div class="caption grey--text">Account State</div>
            <div>{{ client.account_status }}</div>
          </v-col>

          <v-col cols="1">
            <div class="caption grey--text">Status</div>
            <div>{{ client.status }}</div>
          </v-col>

          <v-col cols="2">
            <div class="right">
              <!-- <v-btn text @click="delete_controller(controller.id)">delete</v-btn> -->
              <div>
                <a
                  v-if="client.account_status == 'open'"
                  href="#"
                  class="px-2"
                  @click="close_client(client.id)"
                  >Close Account</a
                >
                <a
                  v-if="client.account_status == 'closed'"
                  href="#"
                  class="px-2"
                  @click="open_client(client.id)"
                  >Open Account</a
                >
              </div>
              <div>
                <a href="#" class="px-2" @click="active_client(client.id)"
                  >Set Active</a
                >
              </div>
              <div>
                <a href="#" class="px-2" @click="edit_client(client)"
                  >Modify</a
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <a class="mx-1" href="/">Close</a>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import AddClientDlg from "@/components/AddClientDlg.vue";
import DeleteClientDlg from "@/components/DeleteClientDlg.vue";

export default {
  components: { Progressbar, AddClientDlg, DeleteClientDlg },

  mounted: function () {
    console.log("hello");
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_clients();
  },

  data() {
    return {
      clients: [],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_clients() {
      let that = this;
      this.$http
        .get("/system/get_clients")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.clients = response.data.clients;
            that.clients.forEach((el) => {
              if (el.status == "active") {
                el.display = "yellow lighten-1";
              }
            });
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
    close_client(id) {
      let that = this;
      this.$http
        .post("/system/close_client/" + id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.reload_clients();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    open_client(id) {
      let that = this;
      this.$http
        .post("/system/open_client/" + id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.reload_clients();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    active_client(id) {
      let that = this;
      this.$http
        .post("/system/set_active_client/" + id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            that.reload_clients();
            that.$store.dispatch("switch_client", response.data.active_client);
            that.$store.dispatch("set_fdi_status", response.data.fdi_status);
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    edit_client(client) {
      this.$refs.addClientDlg.client = JSON.parse(
        JSON.stringify(client)
      );
      this.$refs.addClientDlg.dialog = true;
    },
  },
};
</script>

<style scoped>
.btn {
  text-transform: none !important;
}
.no_underline {
  text-decoration: none;
}

input {
  height: 50px;
}

.border {
  border: 1px groove #ddd !important;
}

.v-chip.complete {
  background: #3cd1c2 !important;
}

.v-chip.ongoing {
  background: orange !important;
}

.v-chip.overdue {
  background: tomato !important;
}
</style>
