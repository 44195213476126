<template>
  <div class="group_structure">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/" class="px-8 pointer" tag="div"
        ><span class=""> Ultimate Beneficial Owner </span></router-link
      >

      <router-link to="/direct_shareholders" class="px-8 pointer" tag="div"
        ><span class=""> Direct Shareholder </span></router-link
      >
      <router-link to="/directors" class="px-8 pointer" tag="div"
        ><span class=""> Board Resolutions  </span></router-link
      >
      <router-link to="/signatory" class="px-8 pointer" tag="div"
        ><span class=""> Authorized Signatory </span></router-link
      >
      <router-link
        to="/group_structure"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Group Structure </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card>
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Group Structure
          </h3>

          <v-spacer></v-spacer>
        </v-card-title>

        <v-form :ref="`form${gs.id}`">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1">a)</v-col>
                <v-col cols="11">Group Structure</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input class="mt-2"
                    outlined
                      v-model="image"
                      accept="application/pdf"
                      :rules="uploadRules2"
                      @change="update_gs"
                      clear-icon=""
                    >
                    </v-file-input>
                  </div>
                  <div class="ma-2 mx-12 px-12">
                    <v-img
                      v-if="gs.file_type === 'image'"
                      :src="gs.cert_file"
                      @click="show_image(gs.cert_file)"
                    ></v-img>
                    <div
                      v-if="gs.file_type === 'pdf'"
                      @click="show_pdf(gs.cert_file)"
                    >
                      <pdf :src="gs.cert_file" width="100%" height="100%" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-form>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <router-link to="/signatory" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/wfoe/name" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";

import pdf from "vue-pdf";

export default {
  components: { Progressbar, CertImgDlg, NoteDlg, pdf },

  mounted: function () {
    this.$refs.progress1.bk1 = "/todo2.png";
    this.$refs.progress1.txt1 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_gs();
  },

  data() {
    return {
      gs: {
        id: "",
        cert_file: "",
      },

      image: "",
      uploadRules2: [
        (file) => {
          if (file) {
            return file.size < 10000000 || "file size should be less than 10 MB!";
          }
        },
      ],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_gs() {
      console.log("reload_gs");
      let that = this;
      this.$http
        .get("/controllers/get_group_structure")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.gs = response.data.gs;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_gs() {
      console.log("update_gs...");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_gs();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_gs();
        return;
      }
      if (this.$refs["form" + this.gs.id].validate()) {
        let formData = new FormData();
        let that = this;
        if (this.image) {
          formData.append("file", this.image);
        }
        if (this.gs.id) {
          formData.append("gs_id", this.gs.id);
        }
        this.$http
          .post("/controllers/update_gs", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_gs();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Group structure needs to at least disclose the holding structure until the ultimate beneficial owner. You could use the wording like China WFOE in the group structure if the English name of the newly-established entity has not been decided yet. Please indicate the name same with its Registration Certificate/Passport in the Group Structure.";
      }
      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>