var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submit_logs"},[_c('Progressbar',{ref:"progress1",staticClass:"hidden-sm-and-down"}),_c('v-divider'),_c('v-toolbar',{staticClass:"lighten-1",attrs:{"color":"","flat":""}},[_c('router-link',{staticClass:"px-8 pointer",attrs:{"to":"/summary","tag":"div"}},[_c('span',{},[_vm._v(" Checklist ")])]),_c('router-link',{staticClass:"px-8 pointer font-weight-bold subtitle-1",attrs:{"to":"/submit_logs","tag":"div"}},[_c('span',{staticClass:"text-decoration-underline blue--text"},[_vm._v(" Submit Logs ")])])],1),_c('v-container',{staticClass:"my-3 mx-auto"},[_c('v-row',{staticClass:"ma-4"},[_c('h3',{staticClass:"title text-decoration-underline blue--text"},[_vm._v(" Submit Logs ")])]),_c('v-card',{staticClass:"mb-4 pb-4"},_vm._l((_vm.logs),function(log){return _c('v-row',{key:log.id,staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{class:("font-italic " + (log.operation == 'reject'
                ? 'red--text font-weight-bold'
                : 'blue--text')),attrs:{"cols":"11"}},[_vm._v(_vm._s(log.operator))])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{class:("font-italic " + (log.operation == 'reject'
                ? 'red--text font-weight-bold'
                : 'blue--text')),attrs:{"cols":"11"}},[_vm._v(_vm._s(log.operation))])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic " + (log.operation == 'reject'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(log.comment))])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-spacer'),_c('span',{class:("font-italic " + (log.operation == 'reject'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(log.create_time))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }