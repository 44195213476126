<template>
  <div class="wfoe_name">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/wfoe/name" class="px-8 pointer" tag="div"
        ><span class=""> Name </span></router-link
      >

      <router-link to="/wfoe/capital" class="px-8 pointer" tag="div"
        ><span class=""> Capital</span></router-link
      >
      <router-link to="/wfoe/business_scope" class="px-8 pointer" tag="div"
        ><span class=""> Business Scope </span></router-link
      >
      <router-link to="/wfoe/registered_address" class="px-8 pointer" tag="div"
        ><span class="">Registered Address </span></router-link
      >
      <router-link
        to="/wfoe/operational_period"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Other Information </span></router-link
      >
    </v-toolbar>
     <v-row class="ma-4">
      <note-dlg ref="noteDlg" />

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card>
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Operational Period
          </h3>

          <v-spacer></v-spacer>
        </v-card-title>

        <v-form ref="form">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1"> a) </v-col>
                <v-col cols="11"> Proposed Operational Period</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-radio-group
                    v-model="wfoe.op_type"
                    @change="update_wfoe"
                  >
                    <v-radio
                      key="1"
                      label="Indefinite Period"
                      value="indefinite"
                    ></v-radio>
                    <v-radio
                      key="2"
                      label="Fixed Year"
                      value="fixed_year"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    :disabled="wfoe.op_type == 'indefinte'"
                    v-model="wfoe.operational_period"
                    @change="update_wfoe"
                    :rules="opRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-form>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <router-link to="/wfoe/registered_address" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/pa/wfoe_directors" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import NoteDlg from "@/components/NoteDlg.vue";


export default {
  components: { Progressbar, NoteDlg },

  mounted: function () {
    this.$refs.progress1.bk2 = "/todo2.png";
    this.$refs.progress1.txt2 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_wfoe();
  },

  data() {
    return {
      wfoe: {
        id: "",
        operational_period: 999,
        op_type:'indefinite'
      },
      opRules: [
        (v) => {
          if (v) {
            return (
              (parseInt(v) > 0 && parseInt(v) <= 999) ||
              "Operaton period must be in 1 to 999 years"
            );
          } else {
            return true;
          }
        },
      ],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_wfoe() {
      console.log("reload_wfoe");
      let that = this;
      this.$http
        .get("/wfoe/get_wfoe")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.wfoe = response.data.wfoe;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_wfoe() {
      console.log("update_wfoe...");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_wfoe();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_wfoe();
        return;
      }
      if (this.$refs["form"]) {
        let formData = new FormData();
        let that = this;

        formData.append("wfoe_id", this.wfoe.id);
        formData.append("operational_period", this.wfoe.operational_period);
        formData.append("op_type", this.wfoe.op_type)
        formData.append("update_flag", "operational_period");

        this.$http
          .post("/wfoe/update_wfoe", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (resp) {
            if (resp.data.status == "success") {
              console.log("SUCCESS!");
              that.reload_wfoe();
            } else {
              that.$EventBus.$emit("toast", resp.data.msg);
            }
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },
    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "There is no legal limitation on proposed operational period, but generally 30 years or indefinite period.";
      } 
      this.$refs.noteDlg.dialog = true;
    },
  },
  
};


</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>