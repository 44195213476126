<template>
  <div class="directors">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/" class="px-8 pointer" tag="div"
        ><span class=""> Ultimate Beneficial Owner </span></router-link
      >

      <router-link to="/direct_shareholders" class="px-8 pointer" tag="div"
        ><span class=""> Direct Shareholder </span></router-link
      >
      <router-link
        to="/directors"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Board Resolutions </span></router-link
      >
      <router-link to="/signatory" class="px-8 pointer" tag="div"
        ><span class=""> Authorized Signatory </span></router-link
      >
      <router-link to="/group_structure" class="px-8 pointer" tag="div"
        ><span class=""> Group Structure </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />

      <v-spacer></v-spacer>
     
    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card
        v-for="(director, idx) in directors"
        :key="director.id"
        class="mb-4"
      >
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Board Resolution {{ idx + 1 }}
          </h3>

          <v-spacer></v-spacer>
          <span
            class="pointer mr-n3 mt-n8"
            @click.stop="delete_director(director)"
            ><v-icon class="red--text">mdi-close-circle</v-icon></span
          >
        </v-card-title>

        <v-form ref="form">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1">a)</v-col>
                <v-col cols="11">Name of Director of Direct Shareholder  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="director.name"
                    @change="update_director(director, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">b)</v-col>
                <v-col cols="11">Position of Director of Direct Shareholder  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="director.position"
                    @change="update_director(director, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            
          </v-row>

          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>

            <v-col cols="6">
              <v-row no-gutters class="mt-n8">
                <v-col cols="1">c)</v-col>
                <v-col cols="11"> Passport of Director of Direct Shareholder  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                    clear-icon=""
                      v-model="director.image"
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click below to upload passport"
                      :rules="uploadRules2"
                      @change="update_director(director, idx)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12 ma-2">
                    <v-img
                      v-if="director.file_type === 'image'"
                      :src="director.passport"
                      @click="show_image(director.passport)"
                    ></v-img>
                    <div
                      v-if="director.file_type === 'pdf'"
                      @click="show_pdf(director.passport)"
                    >
                      <pdf
                        :src="director.passport"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card>

      <v-row class="ma-4">
        <div>
          <span
            class="text-decoration-underline blue--text pointer"
            @click.stop="addNewDirector"
            >Add A New Director</span
          >
        </div>
        <v-spacer></v-spacer>
        <router-link to="/shareholders" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/signatory" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";

import pdf from "vue-pdf";

export default {
  components: { Progressbar, CertImgDlg, NoteDlg, pdf },

  mounted: function () {
    this.$refs.progress1.bk1 = "/todo2.png";
    this.$refs.progress1.txt1 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_directors();
  },

  data() {
    return {
      directors: [],

      uploadRules2: [
        (file) => {
          if (file) {
            return file.size < 10000000 || "file size should be less than 10 MB!";
          } else {
            return true;
          }
        },
      ],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_directors() {
      console.log("reload_directors");
      let that = this;
      this.$http
        .get("/controllers/get_directors")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.directors = response.data.directors;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    addNewDirector() {
      console.log("add New Director");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_directors();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_directors();
        return;
      }
      let that = this;
      this.$http
        .get("/controllers/add_new_director")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.reload_directors();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_director(director, idx) {
      console.log("update_diretor...");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_directors();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
       this.reload_directors();
        return;
      }
      if (this.$refs.form[idx].validate()) {
        let formData = new FormData();
        let that = this;
        if (director.image) {
          formData.append("file", director.image);
        }
        if (director.id) {
          formData.append("director_id", director.id);
        }
        formData.append("name", director.name);
        formData.append("position", director.position)
        this.$http
          .post("/controllers/update_director", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_directors();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },
    delete_director(director) {
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_directors();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_directors();
        return;
      }
      let that = this;
      this.$http
        .post("/controllers/delete_director/" + director.id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.dialog = false;
            that.$EventBus.$emit(
              "toast",
              "Delete Director " + director.name + " successfully!"
            );
            that.reload_directors();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },
    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please confirm the name of director should be identical with his/her passport. ";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please upload the colorful scan copy of passport in A4 (210mm * 297mm) format, with 1:1 proportion in PDF version.";
      }
      this.$refs.noteDlg.dialog = true;
    },

    show_sample() {
      this.$refs.certImgDlg.url = require("@/assets/sample-photo.jpeg");
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>