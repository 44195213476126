<template>
  <div class="submit_logs">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
        <router-link
        to="/summary"
        class="px-8 pointer"
        tag="div"
        ><span class=""> Checklist </span></router-link
      >
      <router-link
        to="/submit_logs"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Submit Logs </span></router-link
      >
    </v-toolbar>

    <v-container class="my-3 mx-auto">
      <v-row class="ma-4">
        <h3 class="title text-decoration-underline blue--text">
          Submit Logs
        </h3>
      </v-row>
      <v-card
        class="mb-4 pb-4"
      >
        

        <v-row
          v-for="log in logs"
          :key="log.id"
          class="px-3"
        >
          <v-col cols="1"> 
          </v-col>
        <v-col cols="2">
            <v-row no-gutters>
              <v-col cols="1"> </v-col>
              <v-col :class="`font-italic ${
                log.operation == 'reject'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
                cols="11"
                >{{ log.operator }}</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row no-gutters>
              <v-col cols="1"> </v-col>
              <v-col :class="`font-italic ${
                log.operation == 'reject'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
                cols="11"
                >{{ log.operation }}</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span :class="`font-italic ${
                log.operation == 'reject'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ log.comment }}</span
            >
          </v-col>
          <v-col cols="3">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic ${
                log.operation == 'reject'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ log.create_time }}</span
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";

export default {
  components: { Progressbar },

  mounted: function () {
    this.$refs.progress1.bk4 = "/todo2.png";
    this.$refs.progress1.txt4 = "white--text"
  },

  data() {
    return {
      logs: [],
    };
  },

  created: function () {
    // Make a request for a user with a given ID
    this.get_submit_logs();
  },

  methods: {
    get_submit_logs() {
      console.log("get_submit_logs");
      let that = this;
      this.$http
        .get("/summary/get_submit_logs")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.logs = response.data.logs;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
  },
};
</script>
