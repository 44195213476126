<template>
  <div class="signatory">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/" class="px-8 pointer" tag="div"
        ><span class=""> Ultimate Beneficial Owner </span></router-link
      >

      <router-link to="/direct_shareholders" class="px-8 pointer" tag="div"
        ><span class=""> Direct Shareholder </span></router-link
      >
      <router-link to="/directors" class="px-8 pointer" tag="div"
        ><span class=""> Board Resolutions  </span></router-link
      >
      <router-link
        to="/signatory"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Authorized Signatory</span></router-link
      >
       <router-link to="/group_structure" class="px-8 pointer" tag="div"
        ><span class=""> Group Structure </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />

      <v-spacer></v-spacer>
     
    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card class="mb-4">
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Authorized signatory
          </h3>

          <v-spacer></v-spacer>
        </v-card-title>

        <v-form :ref="`form${signatory.id}`">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1"> a) </v-col>
                <v-col cols="11"> Name of Authorized Signatory </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="signatory.name"
                    @change="update_signatory"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">b)</v-col>
                <v-col cols="11">Passport of the authorized signatory</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                      v-model="image"
                      accept="application/pdf"
                      :rules="uploadRules2"
                      @change="update_signatory"
                      clear-icon=""
                    >
                    </v-file-input>
                  </div>
                  <div class="ma-2 mx-12 px-12">
                    <v-img
                      v-if="signatory.file_type === 'image'"
                      :src="signatory.passport"
                      @click="show_image(signatory.passport)"
                    ></v-img>
                    <div
                      v-if="signatory.file_type === 'pdf'"
                      @click="show_pdf(signatory.passport)"
                    >
                      <pdf
                        :src="signatory.passport"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>

        </v-form>

        <v-form ref="board">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">c)</v-col>
                <v-col cols="11">Authenticated Board Resolutions Notarized by the Notary Office in your Country and Certified by Chinese Embassy/Consulate</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                      v-model="board_resolution"
                      accept="application/pdf"
                      :rules="uploadRules2"
                      @change="update_board_resolution"
                      clear-icon=""
                    >
                    </v-file-input>
                  </div>
                  <div class="ma-2 mx-12 px-12">
                    <v-img
                      v-if="br.file_type === 'image'"
                      :src="br.cert_file"
                      @click="show_image(br.cert_file)"
                    ></v-img>
                    <div
                      v-if="br.file_type === 'pdf'"
                      @click="show_pdf(br.cert_file)"
                    >
                      <pdf
                        :src="br.cert_file"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
             <v-col cols="2"></v-col>
            <v-col cols="3">
             
               <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(3)"
              >
                &lt; Note 3 &gt;</span
              >
                 <v-spacer></v-spacer>
                <div  class="mt-2 font-italic">
                  <span>click</span>
                  <a :href="url" download="" class="pointer font-italic text-decoration-underline blue--text mx-2"> here </a>
                  <span>  to download Sample Board Resolutions</span>
              </div>
            </v-col>
            
          </v-row>
          
        </v-form>
              </v-card>

        <v-divider></v-divider>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <router-link to="/directors" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/group_structure" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";


import pdf from "vue-pdf";

export default {
  components: { Progressbar, CertImgDlg, NoteDlg, pdf },

  mounted: function () {
    this.$refs.progress1.bk1 = "/todo2.png";
    this.$refs.progress1.txt1 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_signatory();
    this.reload_br();
  },

  data() {
    return {
      url: process.env.VUE_APP_BASE_URL + "/system/download_sbr/",
      signatory: {
        id: "",
        name: "",
        passport: "",
      },

      br: {
        id: "",
        cert_file: "",
      },

      image: "",
      board_resolution: '',
      uploadRules2: [
        (file) => {
          if (file) {
            return file.size < 10000000 || "file size should be less than 10 MB!";
          }
        },
      ],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_signatory() {
      console.log("reload_signatory");
      let that = this;
      this.$http
        .get("/controllers/get_signatory")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.signatory = response.data.signatory;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    reload_br() {
      console.log("reload board resolution");
      let that = this;
      this.$http
        .get("/controllers/get_board_resolution")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.br = response.data.br;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_signatory() {
      console.log("update_signatory...");
            if (this.userRole == 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Agent cannot modify client FDI Information!"
            );
            this.reload_signatory();
            return
      }
            if (this.fdiStatus != 'prepare') {
        this.$EventBus.$emit(
              "toast",
              "Cannot modify FDI Information! in " + this.fdiStatus + " status"
            );
            this.reload_signatory();
            return
      }
      if (this.$refs["form" + this.signatory.id].validate()) {
        let formData = new FormData();
        let that = this;
        if (this.image) {
          formData.append("file", this.image);
        }
        if (this.signatory.id) {
          formData.append("signatory_id", this.signatory.id);
        }
        formData.append("name", this.signatory.name);
        this.$http
          .post("/controllers/update_signatory", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_signatory();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },


    update_board_resolution() {
      console.log("update_br...");
            if (this.userRole == 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Agent cannot modify client FDI Information!"
            );
            this.reload_br();
            return
      }
            if (this.fdiStatus != 'prepare') {
        this.$EventBus.$emit(
              "toast",
              "Cannot modify FDI Information! in " + this.fdiStatus + " status"
            );
            this.reload_br();
            return
      }
      if (this.$refs["board"].validate()) {
        let formData = new FormData();
        let that = this;
        if (this.board_resolution) {
          formData.append("file", this.board_resolution);
        }
        if (this.br.id) {
          formData.append("br_id", this.br.id);
        }
        this.$http
          .post("/controllers/update_br", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_br();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please note only one person could be authorized to be the signatory person. Usually would be the chairman of board/executive director of the direct shareholder but it also could be any person based on your internal management.";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please upload the colorful scan copy of passport in A4 (210mm * 297mm) format, with 1:1 proportion in PDF version. ";
      } else if (idx == 3) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text = 
          "Please note that the original board resolution shall be notarized by the notary office in your country and certified by Chinese Embassy/Consulate. Sample as below. Please kindly fill in and move the remarks in the document, print out on A4 paper (Single-sided, A4 size means 210mm×297mm), arrange the signature according to the instruction with black ink/fountain pen, and ensure the signature specimen is the same as the one on the passport."
      }
      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>