var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary"},[_c('Progressbar',{ref:"progress1",staticClass:"hidden-sm-and-down"}),_c('v-divider'),_c('v-toolbar',{staticClass:"lighten-1",attrs:{"color":"","flat":""}},[_c('router-link',{staticClass:"px-8 pointer font-weight-bold subtitle-1",attrs:{"to":"/summary","tag":"div"}},[_c('span',{staticClass:"text-decoration-underline blue--text"},[_vm._v(" Checklist ")])]),_c('router-link',{staticClass:"px-8 pointer",attrs:{"to":"/submit_logs","tag":"div"}},[_c('span',{},[_vm._v(" Submit Logs ")])])],1),_c('v-container',{staticClass:"my-3 mx-auto"},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"ma-4"},[_c('h3',{staticClass:"title text-decoration-underline blue--text"},[_vm._v(" Investor Information ")])]),_vm._l((_vm.summary.checklist1),function(section){return _c('v-card',{key:section.title,staticClass:"mb-4 pb-4"},[_c('v-card-title',[_c('h3',{staticClass:"title font-weight-bold blue--text ml-1"},[_vm._v(" "+_vm._s(section.title)+" ")])]),_vm._l((section.items),function(check_item){return _c('v-row',{key:check_item.id,staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{class:check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : '',attrs:{"cols":"11"}},[_vm._v(_vm._s(check_item.desc))])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic text-decoration-underline " + (check_item.status == 'fail'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(check_item.status))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic " + (check_item.status == 'fail'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(check_item.fail_cause))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":"comment by RSM"},on:{"change":function($event){return _vm.update_comment(check_item)}},model:{value:(check_item.comment),callback:function ($$v) {_vm.$set(check_item, "comment", $$v)},expression:"check_item.comment"}})],1)],1)})],2)}),_c('v-row',{staticClass:"ma-4"},[_c('h3',{staticClass:"title text-decoration-underline blue--text"},[_vm._v(" China WFOE Information ")])]),_vm._l((_vm.summary.checklist2),function(section){return _c('v-card',{key:section.title,staticClass:"mb-4 pb-4"},[_c('v-card-title',[_c('h3',{staticClass:"title font-weight-bold blue--text ml-1"},[_vm._v(" "+_vm._s(section.title)+" ")])]),_vm._l((section.items),function(check_item){return _c('v-row',{key:check_item.id,staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{class:check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : '',attrs:{"cols":"11"}},[_vm._v(_vm._s(check_item.desc))])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic text-decoration-underline " + (check_item.status == 'fail'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(check_item.status))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic  " + (check_item.status == 'fail'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(check_item.fail_cause))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":"comment by RSM"},on:{"change":function($event){return _vm.update_comment(check_item)}},model:{value:(check_item.comment),callback:function ($$v) {_vm.$set(check_item, "comment", $$v)},expression:"check_item.comment"}})],1)],1)})],2)}),_c('v-row',{staticClass:"ma-4"},[_c('h3',{staticClass:"title text-decoration-underline blue--text"},[_vm._v(" Position Arrangement ")])]),_vm._l((_vm.summary.checklist3),function(section){return _c('v-card',{key:section.title,staticClass:"mb-4 pb-4"},[_c('v-card-title',[_c('h3',{staticClass:"title font-weight-bold blue--text ml-1"},[_vm._v(" "+_vm._s(section.title)+" ")])]),_vm._l((section.items),function(check_item){return _c('v-row',{key:check_item.id,staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{class:check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : '',attrs:{"cols":"11"}},[_vm._v(_vm._s(check_item.desc))])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic text-decoration-underline " + (check_item.status == 'fail'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(check_item.status))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-spacer'),_c('span',{class:("font-italic  " + (check_item.status == 'fail'
                ? 'red--text font-weight-bold'
                : 'blue--text'))},[_vm._v(" "+_vm._s(check_item.fail_cause))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":"comment by RSM"},on:{"change":function($event){return _vm.update_comment(check_item)}},model:{value:(check_item.comment),callback:function ($$v) {_vm.$set(check_item, "comment", $$v)},expression:"check_item.comment"}})],1)],1)})],2)}),_c('v-row',{staticClass:"ma-4"},[(_vm.result == 'pass')?_c('div',[_c('a',{staticClass:"mx-1",attrs:{"href":_vm.report_url}},[_vm._v(" Download PDF report ")])]):_vm._e(),(_vm.result == 'pass' && _vm.userRole == 'staff')?_c('div',[_c('a',{staticClass:"mx-1",attrs:{"href":_vm.xls_url}},[_vm._v(" Download Excel report ")])]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"small":""},on:{"click":_vm.check_fdi}},[_vm._v("Run Auto-Check")]),(_vm.userRole == 'client')?_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.result == 'fail' || _vm.fdiStatus != 'prepare',"small":""},on:{"click":_vm.submit_fdi}},[_vm._v("Submit")]):_vm._e(),(_vm.userRole == 'staff')?_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.result == 'fail' || _vm.fdiStatus != 'submit',"small":""},on:{"click":_vm.approve_fdi}},[_vm._v("Approve")]):_vm._e(),(_vm.userRole == 'staff')?_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.result == 'fail' || _vm.fdiStatus == 'prepare',"small":""},on:{"click":_vm.reject_fdi}},[_vm._v("Reject")]):_vm._e()],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }