<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
        <v-card-title class="title">
            {{ title }}
             <v-spacer></v-spacer>
        <span class="pointer mr-n4 mt-n6" @click.stop="dialog=!dialog"
          ><v-icon class="red--text">mdi-close-circle</v-icon></span
        >
        </v-card-title>
        
        <v-divider></v-divider>
      <v-card-text class="ma-4 font-italic">
        <div class="mx-2">
          <span class="body-1" v-html="text"/>
         
        </div>
       
      </v-card-text>
       
      <v-divider></v-divider>
      <v-img v-if="show_img" :src="image" contain class="mt-5"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      text: "",
      title: "",
    };
  },
};
</script>
