<template>
      <v-dialog
        v-model="dialog"
        max-width="800px"
      >
        <template v-slot:activator="{ on, attrs }">
          <a
            v-bind="attrs"
            v-on="on"
            href="#"
          >
          Add New Client
          </a>
        </template>
  
        <v-card>
          <v-card-title>
            <h3 class="subheading">Client Information</h3>
          </v-card-title>
  
          <v-card-text>
            <v-form class="pa-3" ref="form">

            <v-row align="baseline" class="short_row">
                <v-col cols="1">
                <span>a)</span></v-col>
                <v-col cols="6">
                <v-text-field dense v-model="client.company_name" label="Company Name of Client" :rules="inputRules"></v-text-field>
                </v-col>
                
                
            </v-row>
            <v-row align="baseline" class="short_row">
                <v-col cols="1">
                <span>b)</span></v-col>
                <v-col cols="6">
                <v-text-field  dense v-model="client.username" label="Username" :rules="inputRules" :readonly="client.id !='' "></v-text-field>
                </v-col>
            </v-row>

            <v-row align="baseline" class="short_row">
                <v-col cols="1">
                <span>c)</span></v-col>
                <v-col cols="6">
                <v-text-field dense v-model="client.password" label="Password" :rules="inputRules"></v-text-field>
                </v-col>
            </v-row>

            <v-row align="baseline" class="short_row">
                <v-col cols="1">
                <span>d)</span></v-col>
                <v-col cols="6">
                <v-text-field dense v-model="client.email" label="Email" :rules="inputRules"></v-text-field>
                </v-col>
            </v-row>
            </v-form>
          </v-card-text>
              
              
            <v-card-actions>
                <v-spacer></v-spacer>
                 <v-btn outlined class="ma-4" @click="submit_client">Submit</v-btn>
            </v-card-actions>

        </v-card>
      </v-dialog>
</template>

<script>
export default {
    data () {
        return {
        dialog: false,
        client:{
        id:'',
        company_name: '',
        username: '',
        password:'',
        email:''
        },
        inputRules: [
            v => v.length >= 3 || 'Minimum length is 3 characters'
        ],
        
        }
    },
    methods: {
        submit_client() {
            if (this.$refs.form.validate()) {

              let formData = new FormData();
              let that = this;
              formData.append('id', this.client.id);
              formData.append('company_name', this.client.company_name);
              formData.append('username', this.client.username);
              formData.append('pw',this.client.password);
              formData.append('email', this.client.email)
              this.$http.post('/system/add_client',
              formData,
              {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(){
                console.log('SUCCESS!');
                that.dialog = false;
                that.$emit('add-client')
            })
            .catch(function(){
              console.log('FAILURE!!');
            }).then(function(){
              that.client.company_name =  '';
              that.client.username =  '';
              that.client.password = '';
              that.client.email = '';
              that.$refs.form.reset();
            });
            
            }
        },
        
      
    },

}
</script>

<style scoped>
.short_row {
    height: 60px;
}
.high_row {
    height: 150px;
}
</style>