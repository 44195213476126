<template>
  <div class="wfoe_directors">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/pa/wfoe_directors" class="px-8 pointer" tag="div"
        ><span class=""> Director </span></router-link
      >

      <router-link to="/pa/supervisor" class="px-8 pointer" tag="div"
        ><span class=""> Supervisor </span></router-link
      >
      <router-link to="/pa/gm" class="px-8 pointer" tag="div"
        ><span class=""> General Manager </span></router-link
      >
      <router-link
        to="/pa/legal_repr"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Legal Representative </span></router-link
      >
      <router-link to="/pa/finance_controller" class="px-8 pointer" tag="div"
        ><span class=""> Finance Controller </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <resp-note-dlg ref="respNoteDlg" />
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />
      <PANotifyDlg />

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card class="mb-4">
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Legal Representative of China WFOE
          </h3>

          <v-spacer></v-spacer>

          <span class="body-2 font-italic">click</span>
          <a
            class="mx-1 body-2 font-italic text-decoration-underline"
            @click.stop="show_resp()"
          >
            here
          </a>
          <span class="body-2 font-italic">
            to know the Responsibility of Legal Representative
          </span>
        </v-card-title>

        <v-form ref="paform">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1">a) </v-col>
                <v-col cols="11">Appoint the Legal Representative </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-combobox
                  outlined
                    v-model="pa_info.legal_repr"
                    :items="reprs"
                    item-text="name"
                    item-value="id"
                    placeholder="Legal Representative of China WFOE"
                    :rules="[rules.repr(repr_names)]"
                    @change="update_painfo"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3 pb-4">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters class="mt-n5">
                  <v-col cols="1">b) </v-col>
                  <v-col cols="11"> Authenticated Passport Notarized by the Notary Office in your Country and Certified by Chinese Embassy/Consulate </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"> </v-col>
                  <v-col cols="11">
                    <div>
                      <v-file-input
                      outlined
                      clear-icon=""
                        v-model="pa_info.passport_image"
                        accept="application/pdf"
                        hint="accept pdf files"
                        label="Scan copy of passport"
                        :rules="uploadRules2"
                        @change="update_painfo"
                      >
                      </v-file-input>
                    </div>
                    <div class="mx-12 px-12">
                      <v-img
                        v-if="pa_info.passport_filetype === 'image'"
                        :src="pa_info.legal_repr_passport"
                        @click="show_image(pa_info.legal_repr_passport)"
                      ></v-img>
                      <div
                        v-if="pa_info.passport_filetype === 'pdf'"
                        @click="show_pdf(pa_info.legal_repr_passport)"
                      >
                        <pdf
                          :src="pa_info.legal_repr_passport"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="2">
                <v-spacer></v-spacer>
                <span
                  class="
                    pointer
                    font-italic
                    text-decoration-underline
                    blue--text
                  "
                  @click.stop="showNote(2)"
                >
                  &lt; Note 2 &gt;</span
                >
              </v-col>
            </v-row>

        </v-form>
        <v-divider></v-divider>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>

        <router-link to="/pa/gm" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/pa/finance_controller" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import NoteDlg from "@/components/NoteDlg.vue";
import PANotifyDlg from "@/components/PANotifyDlg.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import RespNoteDlg from "@/components/RespNoteDlg.vue";



import pdf from "vue-pdf";


export default {
  components: { Progressbar, NoteDlg, PANotifyDlg, CertImgDlg, pdf, RespNoteDlg},

  mounted: function () {
    this.$refs.progress1.bk3 = "/todo2.png";
    this.$refs.progress1.txt3 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.get_legal_repr_list();
    this.get_pa_info();
  },

  data() {
    return {
      pa_info: {},
      reprs: [],
      rules: {
        repr(names) {
          return (v) => {
            if (names.length > 0) {
              if (v) {
                console.log("validate:" + names);
                return names.includes(v.name) || "Invald Choice";
              } else {
                return true;
              }
            } else {
              return true;
            }
          };
        },
      },
      uploadRules2: [
        (file) => {
          if (file) {
            return file.size < 10000000 || "file size should be less than 10 MB!";
          } else {
            return true;
          }
        },
      ],
      url: process.env.VUE_APP_BASE_URL + "/system/download_resp_of_director/",
    };
  },

  computed: {
          repr_names() {
      return this.reprs.map((v) => v.name);
    },
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    get_legal_repr_list() {
      console.log("get_legal_repr_list");
      let that = this;
      this.$http
        .get("/pa/get_legal_repr_list")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.reprs = response.data.reprs;
          } else {
            that.$EventBus.$emit("toast", response.data.msg);
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    get_pa_info() {
      console.log("get_pa_info");
      let that = this;
      this.$http
        .get("/pa/get_pa_info")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.pa_info = response.data.pa_info;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_painfo() {
      console.log("update_painfo...");
            if (this.userRole == 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Agent cannot modify client FDI Information!"
            );
            this.get_pa_info();
            this.get_legal_repr_list();

            return
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.get_pa_info();
            this.get_legal_repr_list();   
        return;
      }
      if (this.$refs.paform.validate()) {
        let formData = new FormData();
        let that = this;

        if (this.pa_info.id) {
          formData.append("pa_id", this.pa_info.id);
        }
        formData.append("legal_repr", this.pa_info.legal_repr.id);
        if (this.pa_info.legal_repr_passport) {
          formData.append("passport", this.pa_info.passport_image);
        }
        this.$http
          .post("/pa/update_pa_info", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.get_pa_info();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "MUST be either Executive Director (Chairman if setting up Board of Directors) or General Manager to act as the Legal Representative.";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text = 
          "The original passport of legal representative shall be notarized by the notary office in your country and certified by Chinese Embassy/Consulate. Nevertheless, there is still a possibility for legal representative to provide the original passport for verification (or even be present at AMR) when application for business license according to different authorities’ requirements in practice."
      }
 
      this.$refs.noteDlg.dialog = true;
    },

    show_resp() {
      this.$refs.respNoteDlg.title = "Responsibility of Legal Representative";
      this.$refs.respNoteDlg.text =
        " (1) No need to be based in China but may need to come to China based on the authorities requirements; <br/> \
          (2) Mainly responsible to communicate with different authorities(e.g., AMR, tax bureau, Customs, etc.) in future operation upon their requirements/ sign any legal documents submitted to authorities/ sign contracts or agreements upon your internal control/ participate in other activities(e.g., lawsuit) on behalf of WFOE; <br/> \
          (3) Shall bear administrative/criminal liabilities if the company violates the administrative regulations/criminal laws; Could be listed into Dishonest People Blacklist if the company does not execute the court's judgement or violates the laws/regulations, which could lead to various operation restrictions from different authorities."

      this.$refs.respNoteDlg.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>