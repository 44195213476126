<template>
      <v-dialog
        v-model="cert_img"
        max-width="1000px"
      >

        <v-card>

         <v-img v-if="file_type === 'image'" :src="url" contain
            class="mx-4"
            ></v-img>

        <pdf v-if="file_type === 'pdf'" :src="url"
            class="mx-4"
            />
            
          <v-card-actions>
              <v-spacer></v-spacer>
              <a :href="url" download="" class="mx-6">Save</a>
              <a href="#"  class="px-2" @click="cert_img=false">Close</a> 
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import pdf from 'vue-pdf'
export default {
     components: { pdf }, 
    data () {
        return {
        cert_img: false,
        url:"",
        file_type:""
        }
    },
}
</script>
