<template>
  <v-container class=" white lighten-1" style="max-width:1440px" >
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="11">
    <v-img src="@/assets/logo@2x.png" height="54px" max-width="129px" class="mt-0 ml-1"/>
      </v-col>
     </v-row>
     
   
    <v-img class='mx-auto' src="bk.png"  max-width="620px"/>
    <v-row class="mt-1">
      <div class="prelo-bold mx-auto blue--text font-size-48 ">
      RSM China Applications Login
    </div>
    </v-row>
    
  <v-card  width="400"  elevation="0"
  class="mx-auto mt-n2">
    <!-- <v-card-title >
      <v-img src="rsm-login.png" max-width="80" />
    <h3 class="mx-4">Sign In</h3>
    </v-card-title> -->
    <v-card-text>
      <v-form>
        <div style="color: #00A3DF; font-weight: bold;">Username</div>
        <v-text-field dense
          name="username"
          outlined
          color="blue"
        v-model="username"
        /> 
        <div style="color: #00A3DF; font-weight: bold;" class="mt-n4">Password
        <v-text-field dense
          :type="showPassword ? 'text' : 'password'"
          name="password"
          color="blue"
          v-model="password"
          outlined
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="mt-n8">
      <v-btn outlined @click="login" color="white" style="font-weight: bold; border-width: 2px; border-color: #00A3DF; backgroundColor: #00A3DF; min-width:100% " class="mr-4 text--darken-1">Submit</v-btn>
    </v-card-actions>
  </v-card>
  <!-- <v-row class="">
      <div class="caption mx-auto font-weight-bold">
      This site is intended only for authorized users.
    </div>
    </v-row>
  <v-row>
    <div class="caption mx-auto">
      RSM China is a member of the RSM network and trades as RSM. RSM is the trading name used by the members of the RSM network. Each member of the RSM network is an independent accounting and advisory firm each of which practices in its own right. The RSM network is not itself a separate legal entity of any description in any jurisdiction. The RSM network is administered by RSM International Limited, a company registered in England and Wales (company number 4040598) whose registered office is at 50 Cannon Street, London, EC4N 6JJ. The brand and trademark RSM and other intellectual property rights used by members of the network are owned by RSM International Association, an association governed by article 60 et seq of the Civil Code of Switzerland whose seat is in Zug. Any articles or publications contained within this website are not intended to provide specific business or investment advice. No responsibility for any errors or omissions nor loss occasioned to any person or organisation acting or refraining from acting as a result of any material in this website can, however, be accepted by the author(s) or RSM International. You should take specific independent advice before making any business or investment decision.
    </div>
  </v-row>
   -->
  <!-- <v-img src="claim.png" class="mx-auto"></v-img> -->
  <v-row class="my-1 px-3" >
    <span class="font-weight-bold mx-auto caption mb-1">This site is intended only for authorized users.</span>
    <span class="mx-auto caption" style="line-height: 1; letter-spacing: -1px; color: #333333">RSM China is a member of the RSM network and trades as RSM. RSM is the trading name used by the members of the RSM network. Each member of the RSM network is an independent accounting and advisory firm each of which practices in its own right. The RSM network is not itself a separate legal entity of any description in any jurisdiction. The RSM network is administered by RSM International Limited, a company registered in England and Wales (company number 4040598) whose registered office is at 50 Cannon Street, London, EC4N 6JJ. The brand and trademark RSM and other intellectual property rights used by members of the network are owned by RSM International Association, an association governed by article 60 et seq of the Civil Code of Switzerland whose seat is in Zug. Any articles or publications contained within this website are not intended to provide specific business or investment advice. No responsibility for any errors or omissions nor loss occasioned to any person or organisation acting or refraining from acting as a result of any material in this website can, however, be accepted by the author(s) or RSM International. You should take specific independent advice before making any business or investment decision.</span>
  </v-row>
  <v-row class="pt-0 mx-auto" style="backgroundColor: #00A3DF; width:100%;" >
    <div style="backgroundColor: #00A3DF; width:100% text-align:center; font-weight:bold;" class="mx-auto white--text caption my-auto">
      @2021 RSM China 2021, All rights reserved.
    </div>
  </v-row>
  </v-container>
  
</template>
<script>
  export default {
    data(){
      return {
        username : "",
        password : "",
        showPassword: false,
      }
    },
    methods: {
      login: function () {
        let username = this.username
        let password = this.password
        let that = this
        this.$store.dispatch('login', { username, password })
       .then((resp) => {
         console.log(resp.data)
         if (resp.data.role == 'staff') {
            that.$router.push('/clients')
         }else{
         that.$router.push('/')
         }
       }
         )
       
       .catch(err => console.log(err))
      }
    }
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 2px;
}
</style>