<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
        <v-card-title>
            <span>Note</span> 
             <v-spacer></v-spacer>
        <span class="pointer mr-n4 mt-n6" @click.stop="dialog=!dialog"
          ><v-icon class="red--text">mdi-close-circle</v-icon></span
        >
        </v-card-title>
        
        <v-divider></v-divider>
      <v-card-text class="ma-4 font-italic">
        <div class="mx-2">
          <span class="title"> &bull;</span>	 &nbsp; &nbsp; &nbsp; {{ text }}
         
        </div>
       
      </v-card-text>
       
      <v-divider></v-divider>
      <v-img v-if="show_img" :src="image" contain class="mt-5"></v-img>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <a href="#" class="px-2" @click="dialog = false; show_img=false">Close</a>
      </v-card-actions> -->
       
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      text: "",
      image: require('@/assets/capital-note.png'),
      show_img: false,
    };
  },
};
</script>
