import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index.js'

import ActualControllers from "../views/ir/ActualControllers.vue"
import DirectShareholder from "../views/ir/DirectShareholder.vue"
import Directors from "../views/ir/Directors.vue"
import AuthorizedSignatory from "../views/ir/AuthorizedSignatory.vue"
import GroupStructure from "../views/ir/GroupStructure.vue"
import WFOEName from "../views/wfoe/WFOEName.vue"
import WFOECapital from "../views/wfoe/WFOECapital.vue"
import WFOEBusinessScope from "../views/wfoe/WFOEBusinessScope.vue"
import WFOERegisteredAddress from "../views/wfoe/WFOERegisteredAddress.vue"
import WFOEOperationalPeriod from "../views/wfoe/WFOEOperationalPeriod.vue"
import WFOEDirectors from "../views/pa/WFOEDirectors.vue"
import WFOESupervisor from "../views/pa/WFOESupervisor.vue"
import WFOEGM from "../views/pa/WFOEGM.vue"
import WFOEFC from "../views/pa/WFOEFC.vue"
import WFOELegalRepr from "../views/pa/WFOELegalRepr.vue"
import Summary from "../views/Summary.vue"
import SubmitLogs from "../views/SubmitLogs.vue"

import Clients from "../views/Clients.vue"
import Login from '../components/Login.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "ActualControllers",
    component: ActualControllers,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/direct_shareholders",
    name: "DirectShareholders",
    component: DirectShareholder,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/directors",
    name: "Directors",
    component: Directors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/signatory",
    name: "Signatory",
    component: AuthorizedSignatory,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/group_structure",
    name: "gs",
    component: GroupStructure,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/wfoe/name",
    name: "WFOEName",
    component: WFOEName,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/wfoe/capital",
    name: "WFOECapital",
    component: WFOECapital,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/wfoe/business_scope",
    name: "WFOEBusinessScope",
    component: WFOEBusinessScope,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/wfoe/registered_address",
    name: "WFOERegisteredAddress",
    component: WFOERegisteredAddress,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/wfoe/operational_period",
    name: "WFOEOperationalPeriod",
    component: WFOEOperationalPeriod,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/pa/wfoe_directors",
    name: "WFOEDirectors",
    component: WFOEDirectors,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/pa/supervisor",
    name: "WFOESupervisor",
    component: WFOESupervisor,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/pa/gm",
    name: "WFOEGM",
    component: WFOEGM,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/pa/legal_repr",
    name: "WFOELegalRepr",
    component: WFOELegalRepr,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/pa/finance_controller",
    name: "WFOEFC",
    component: WFOEFC,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/summary",
    name: "Summary",
    component: Summary,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/submit_logs",
    name: "SubmitLogs",
    component: SubmitLogs,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects.vue"),
    meta: {
        requiresAuth: true
      }
  },
  

  {
    path: "/team",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/Team.vue"),
    meta: {
        requiresAuth: true
      }
  },

  {
    path: "*",
    name: "notFound",
    component: Login
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;
