<template>
  <div class="summary">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link
        to="/summary"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Checklist </span></router-link
      >
      <router-link to="/submit_logs" class="px-8 pointer" tag="div"
        ><span class=""> Submit Logs </span></router-link
      >
    </v-toolbar>

    <v-container class="my-3 mx-auto">
      <v-form ref="form">
      <v-row class="ma-4">
        <h3 class="title text-decoration-underline blue--text">
          Investor Information
        </h3>
      </v-row>
      <v-card
        v-for="section in summary.checklist1"
        :key="section.title"
        class="mb-4 pb-4"
      >
        <v-card-title>
          <h3 class="title font-weight-bold blue--text ml-1">
            {{ section.title }}
          </h3>
        </v-card-title>

        <v-row
          v-for="check_item in section.items"
          :key="check_item.id"
          class="px-3"
        >
          <v-col cols="5">
            <v-row no-gutters>
              <v-col cols="1"> </v-col>
              <v-col
                cols="11"
                :class="
                  check_item.status == 'fail'
                    ? 'red--text font-weight-bold'
                    : ''
                "
                >{{ check_item.desc }}</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic text-decoration-underline ${
                check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ check_item.status }}</span
            >
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic ${
                check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ check_item.fail_cause }}</span
            >
          </v-col>
          <v-col cols='2'>
            <v-text-field
                    dense
                    placeholder="comment by RSM"
                    v-model="check_item.comment"
                    @change="update_comment(check_item)"
                  ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="ma-4">
        <h3 class="title text-decoration-underline blue--text">
          China WFOE Information
        </h3>
      </v-row>
      <v-card
        v-for="section in summary.checklist2"
        :key="section.title"
        class="mb-4 pb-4"
      >
        <v-card-title>
          <h3 class="title font-weight-bold blue--text ml-1">
            {{ section.title }}
          </h3>
        </v-card-title>

        <v-row 
          v-for="check_item in section.items"
          :key="check_item.id"
          class="px-3"
        >
          <v-col cols="5">
            <v-row no-gutters >
              <v-col cols="1"> </v-col>
              <v-col
                cols="11"
                :class="
                  check_item.status == 'fail'
                    ? 'red--text font-weight-bold'
                    : ''
                "
                >{{ check_item.desc }}</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic text-decoration-underline ${
                check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ check_item.status }}</span
            >
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic  ${
                check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ check_item.fail_cause }}</span
            >
          </v-col>
          <v-col cols='2'>
            <v-text-field
                    dense
                    placeholder="comment by RSM"
                    v-model="check_item.comment"
                    @change="update_comment(check_item)"
                  ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="ma-4">
        <h3 class="title text-decoration-underline blue--text">
          Position Arrangement
        </h3>
      </v-row>
      <v-card
        v-for="section in summary.checklist3"
        :key="section.title"
        class="mb-4 pb-4"
      >
        <v-card-title>
          <h3 class="title font-weight-bold blue--text ml-1">
            {{ section.title }}
          </h3>
        </v-card-title>

        <v-row
          v-for="check_item in section.items"
          :key="check_item.id"
          class="px-3"
        >
          <v-col cols="5">
            <v-row no-gutters>
              <v-col cols="1"> </v-col>
              <v-col
                cols="11"
                :class="
                  check_item.status == 'fail'
                    ? 'red--text font-weight-bold'
                    : ''
                "
                >{{ check_item.desc }}</v-col
              >
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic text-decoration-underline ${
                check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ check_item.status }}</span
            >
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span
              :class="`font-italic  ${
                check_item.status == 'fail'
                  ? 'red--text font-weight-bold'
                  : 'blue--text'
              }`"
            >
              {{ check_item.fail_cause }}</span
            >
          </v-col>
          <v-col cols='2'>
            <v-text-field
                    dense
                    placeholder="comment by RSM"
                    v-model="check_item.comment"
                    @change="update_comment(check_item)"
                  ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="ma-4">
        <div v-if="result == 'pass'">
          <a :href="report_url" class="mx-1">
            Download PDF report
          </a>
        </div>
        <div v-if="result == 'pass' && userRole == 'staff'">
          <a :href="xls_url" class="mx-1">
            Download Excel report
          </a>
        </div>
        <v-spacer></v-spacer>
         <v-btn class="mx-2"
          small
          @click="check_fdi"
          >Run Auto-Check</v-btn
        >
        <v-btn class="mx-2"
          v-if="userRole == 'client'"
          :disabled="result == 'fail' || fdiStatus != 'prepare'"
          small
          @click="submit_fdi"
          >Submit</v-btn
        >
        <v-btn class="mx-2"
          v-if="userRole == 'staff'"
          :disabled="result == 'fail' || fdiStatus != 'submit'"
          small
          @click="approve_fdi"
          >Approve</v-btn
        >
        <v-btn class="mx-2"
          v-if="userRole == 'staff'"
          :disabled="result == 'fail' || fdiStatus == 'prepare'"
          small
          @click="reject_fdi"
          >Reject</v-btn
        >
      </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";

export default {
  components: { Progressbar },

  mounted: function () {
    this.$refs.progress1.bk4 = "/todo2.png";
    this.$refs.progress1.txt4 = "white--text"
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
    report_url: function () {
      return (
        process.env.VUE_APP_BASE_URL +
        "/summary/generate_pdf?token=" +
        this.$store.getters.token
      );
    },
    xls_url: function () {
      return (
        process.env.VUE_APP_BASE_URL +
        "/summary/generate_excel?token=" +
        this.$store.getters.token
      );
    },
  },

  data() {
    return {
      summary: {},
      result: "fail",
    };
  },

  created: function () {
    // Make a request for a user with a given ID
    this.fdi_summary();
  },

  methods: {
    fdi_summary() {
      console.log("fdi_summary");
      let that = this;
      this.$http
        .get("/summary/fdi_summary")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.summary = response.data.summary;
            that.result = response.data.result;
            that.$store.dispatch("set_fdi_status", response.data.fdi_status);
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    submit_fdi() {
      let that = this;
      console.log("submit_fdi>>>");
      if (this.userRole == "staff") {
        this.$EventBus.$emit("toast", "Agent cannot Submit FDI Information!");

        return;
      }

      this.$http
        .post("/summary/submit_fdi", {})
        .then(function (resp) {
          if (resp.data.status == "success") {
            console.log("SUCCESS!");
            that.$store.dispatch("set_fdi_status", resp.data.fdi_status);
            //that.reload_wfoe();
          } else {
            //that.$EventBus.$emit("toast", resp.data.msg);

            console.log("fail");
            console.log(resp.data);

            //    that.$EventBus.$emit("toast", resp.data.msg);
            // that.$store.dispatch("logout");
            // that.$router.push("login");
          }
        })
        .catch(function () {
          console.log("FAILURE!");
        })
        .then(function () {
          console.log("then...");
        });
    },


    check_fdi() {
      let that = this;
      console.log("check_fdi>>>");

      this.$http
        .post("/summary/check_fdi_summary", {})
        .then(function (resp) {
          if (resp.data.status == "success") {
            console.log("SUCCESS!");
            that.fdi_summary();
          } else {

            console.log("fail");
            console.log(resp.data);

            //    that.$EventBus.$emit("toast", resp.data.msg);
            // that.$store.dispatch("logout");
            // that.$router.push("login");
          }
        })
        .catch(function () {
          console.log("FAILURE!");
        })
        .then(function () {
          console.log("then...");
        });
    },


    approve_fdi() {
      let that = this;
      console.log("approve_fdi");
      if (this.userRole == "client") {
        this.$EventBus.$emit("toast", "Client cannot approve FDI Information!");

        return;
      }

      this.$http
        .post("/summary/approve_fdi", {})
        .then(function (resp) {
          if (resp.data.status == "success") {
            console.log("SUCCESS!");
            console.log(resp.data)
            that.$store.dispatch("set_fdi_status", resp.data.fdi_status);
            //that.reload_wfoe();
          } else {
            //that.$EventBus.$emit("toast", resp.data.msg);

            console.log("fail");
            console.log(resp.data);

            //    that.$EventBus.$emit("toast", resp.data.msg);
            // that.$store.dispatch("logout");
            // that.$router.push("login");
          }
        })
        .catch(function () {
          console.log("FAILURE!");
        })
        .then(function () {
          console.log("then...");
        });
    },

    reject_fdi() {
      let that = this;
      console.log("reject_fdi");
      if (this.userRole == "client") {
        this.$EventBus.$emit("toast", "Client cannot reject FDI Information!");

        return;
      }

      this.$http
        .post("/summary/reject_fdi", {})
        .then(function (resp) {
          if (resp.data.status == "success") {
            console.log("SUCCESS!");
            console.log(resp.data)
            that.$store.dispatch("set_fdi_status", resp.data.fdi_status);
            //that.reload_wfoe();
          } else {
            //that.$EventBus.$emit("toast", resp.data.msg);

            console.log("fail");
            console.log(resp.data);

            //    that.$EventBus.$emit("toast", resp.data.msg);
            // that.$store.dispatch("logout");
            // that.$router.push("login");
          }
        })
        .catch(function () {
          console.log("FAILURE!");
        })
        .then(function () {
          console.log("then...");
        });
    },

    update_comment(check_item) {
      console.log("update_comment...");
      if (this.userRole != 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Only for RSM!"
            );
            this.fdi_summary();

            return
      }

      if (this.$refs["form"].validate()) {
        let formData = new FormData();
        let that = this;

        formData.append("_id", check_item.id);
        formData.append("comment", check_item.comment);
        

        this.$http
          .post("/summary/update_fdi_comment", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (resp) {
            if (resp.data.status == 'success'){
            console.log("SUCCESS!");
            that.fdi_summary();
              } else {
                  that.$EventBus.$emit("toast", resp.data.msg);
              }
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },
  },
};
</script>
