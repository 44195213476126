<template>
  <div class="shareholders">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/" class="px-8 pointer" tag="div"
        ><span class=""> Ultimate Beneficial Owner </span></router-link
      >

      <router-link
        to="/direct_shareholders"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Direct Shareholder </span></router-link
      >
      <router-link to="/directors" class="px-8 pointer" tag="div"
        ><span class=""> Board Resolutions  </span></router-link
      >
      <router-link to="/signatory" class="px-8 pointer" tag="div"
        ><span class=""> Authorized Signatory </span></router-link
      >
      <router-link to="/group_structure" class="px-8 pointer" tag="div"
        ><span class=""> Group Structure </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <note-dlg ref="noteDlg" />
      <cert-img-dlg ref="certImgDlg" />
      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card
        v-for="(shareholder, idx) in shareholders"
        :key="shareholder.id"
        class="mb-4"
      >
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Direct Shareholder {{ idx + 1 }}
          </h3>

          <v-spacer></v-spacer>
          <span
            class="pointer mr-n3 mt-n8"
            @click.stop="delete_shareholder(shareholder)"
            ><v-icon class="red--text">mdi-close-circle</v-icon></span
          >
        </v-card-title>

        <v-form ref="form">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1">a)</v-col>
                <v-col cols="11"> Name of Direct Shareholder </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="shareholder.name"
                    @change="update_shareholder(shareholder, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">b) </v-col>
                <v-col cols="11">Company Registration Number of Direct Shareholder</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="shareholder.reg_num"
                    @change="update_shareholder(shareholder, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">c) </v-col>
                <v-col cols="11">Country of Direct Shareholder</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-combobox
                    v-model="shareholder.country"
                    :items="countries"
                    item-text="name"
                    item-value="name"
                    :rules="countryRules"
                    @change="update_shareholder(shareholder, idx)"
                    outlined
                  >
                    <template slot="item" slot-scope="data">
                      <country-flag
                        :country="`${data.item.abbr}`"
                        size="normal"
                      />
                      <span class="mx-6">
                        {{ data.item.name }}
                      </span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">d) </v-col>
                <v-col cols="11"> Shareholding ratio </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    v-model="shareholder.ratio"
                    @change="update_shareholder(shareholder, idx)"
                    :rules="ratioRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">e) </v-col>
                <v-col cols="11">
                 Authenticated Registration Certificate Notarized by the Notary Office in your Country and Certified by Chinese Embassy/Consulate
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                      v-model="shareholder.image"
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click here to upload registration certificate"
                      :rules="uploadRules2"
                      multiple
                      outlined
                      clear-icon=""
                      @change="update_shareholder(shareholder, idx)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12">
                    <v-card
                      v-for="certfile in shareholder.cert_file"
                      :key="certfile.path"
                      class="mb-4"
                    >
                      <v-img
                        v-if="certfile.file_type === 'image'"
                        :src="certfile.path"
                        @click="show_image(certfile.path)"
                      ></v-img>
                      <div
                        v-if="certfile.file_type === 'pdf'"
                        @click="show_pdf(certfile.path)"
                      >
                        <pdf :src="certfile.path" width="100%" height="100%" />
                      </div>
                      <v-divider></v-divider>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(3)"
              >
                &lt; Note 3 &gt;</span
              >
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card>

      <v-row class="ma-4">
        <div>
          <span
            class="text-decoration-underline blue--text pointer"
            @click.stop="addNewShareholder"
            >Add A New Shareholder</span
          >
        </div>
        <v-spacer></v-spacer>
        <router-link to="/" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/directors" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";

import pdf from "vue-pdf";

export default {
  components: { Progressbar, CertImgDlg, pdf, NoteDlg },

  mounted: function () {
    this.$refs.progress1.bk1 = "/todo2.png";
    this.$refs.progress1.txt1 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.get_country_list();
    this.reload_shareholders();
  },

  data() {
    return {
      shareholders: [],
      countries: [],

      uploadRules2: [
        (files) => {
          if (files) {
            for (var i = 0; i < files.length; i++) {
              return (
                files[i].size < 10000000 ||
                "file size should be less than 10 MB!"
              );
            }
          } else {
            return true;
          }
        },
      ],

      ratioRules: [
        (v) => {
          if (v) {
            return (
              (this.totalRatio <= 100 && parseFloat(v) > 0.01) ||
              "Ratio must be in 0.01 to " +
                (100 - (this.totalRatio - parseFloat(v))).toFixed(2)
            );
          } else {
            return true;
          }
        },
      ],

      countryRules: [
        (v) => {
          if (v) {
            return this.country_names.includes(v.name) || "Invald country";
          } else {
            return "Invald country";
          }
        },
      ],
    };
  },

  computed: {
    country_names() {
      return this.countries.map((v) => v.name);
    },
    userRole() {
      return this.$store.getters.userRole;
    },
    totalRatio() {
      return this.shareholders.reduce(function (acc, obj) {
        return acc + parseFloat(obj.ratio);
      }, 0);
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    get_country_list() {
      console.log("get_country_list");
      let that = this;
      this.$http
        .get("/controllers/get_country_list")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.countries = response.data.countries;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    reload_shareholders() {
      console.log("reload_shareholders");
      let that = this;
      this.$http
        .get("/controllers/get_shareholders")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.shareholders = response.data.shareholders;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    addNewShareholder() {
      console.log("add New Shareholder");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_shareholders();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_shareholders();
        return;
      }
      let that = this;
      this.$http
        .get("/controllers/add_new_shareholder")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.reload_shareholders();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_shareholder(shareholder, idx) {
      console.log("update_shareholder..");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_shareholders();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_shareholders();
        return;
      }
      if (this.$refs.form[idx].validate()) {
        let formData = new FormData();
        let that = this;
        if (shareholder.image) {
          console.log(shareholder.image);
          for (var i = 0; i < shareholder.image.length; i++) {
            formData.append("files[]", shareholder.image[i]);
          }
        }
        if (shareholder.id) {
          formData.append("shareholder_id", shareholder.id);
        }
        formData.append("name", shareholder.name);
        formData.append("reg_number", shareholder.reg_num);
        formData.append("country", shareholder.country.name);
        formData.append("ratio", shareholder.ratio);
        this.$http
          .post("/controllers/update_shareholder", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_shareholders();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },
    delete_shareholder(shareholder) {
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_shareholders();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_shareholders();
        return;
      }
      let that = this;
      this.$http
        .post("/controllers/delete_shareholder/" + shareholder.id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.dialog = false;
            that.$EventBus.$emit(
              "toast",
              "Delete Shareholder " + shareholder.name + " successfully!"
            );
            that.reload_shareholders();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please confirm the name of direct shareholder should be identical with its registration certificate/passport.";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please insert the number among 0.01%-100%. The shareholding ratio shall at most keep two decimal places (e.g. 0.01%) and the total ratio of direct shareholders shall be 100%.";
      } else if (idx == 3) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "The original authenticated registration certificate shall be notarized by the notary office in your country and certified by Chinese Embassy/Consulate. Our service scope does not cover the notary and authentication process and translation of the authenticated documents. Upon your requests, we could find a qualified translation company in PRC for Chinese translation and charge the fee in disbursements. It usually costs about RMB 100-150 per page. Considering the time schedule, you could upload the non-authenticated/notarized version first for our document preparation and updated it after completing the notarization and authentication.";
      }

      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>