<template>
  <div class="wfoe_directors">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/pa/wfoe_directors" class="px-8 pointer" tag="div"
        ><span class=""> Director </span></router-link
      >

      <router-link
        to="/pa/supervisor"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Supervisor </span></router-link
      >
      <router-link to="/pa/gm" class="px-8 pointer" tag="div"
        ><span class=""> General Manager </span></router-link
      >
      <router-link to="/pa/legal_repr" class="px-8 pointer" tag="div"
        ><span class=""> Legal Representative </span></router-link
      >
      <router-link to="/pa/finance_controller" class="px-8 pointer" tag="div"
        ><span class=""> Finance Controller </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <resp-note-dlg ref="respNoteDlg" />
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />
      <PANotifyDlg />

      <v-spacer></v-spacer>
    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card class="mb-4">
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">Supervisor</h3>
                    <v-spacer></v-spacer>

          <span class="body-2 font-italic">click</span>
          <a
            class="mx-1 body-2 font-italic text-decoration-underline"
            @click.stop="show_resp()"
          >
            here
          </a>
          <span class="body-2 font-italic">
            to know the Responsibility of Supervisor
          </span>
        </v-card-title>

        <v-form ref="xform">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1">a) </v-col>
                <v-col cols="11">Name</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="supervisor.name"
                    @change="update_director(supervisor, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">b)</v-col>
                <v-col cols="11"> Passport No </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="supervisor.passport_no"
                    @change="update_director(supervisor, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">c) </v-col>
                <v-col cols="11">Phone Number</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="supervisor.phone_number"
                    @change="update_director(supervisor, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">d) </v-col>
                <v-col cols="11">Email</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="supervisor.email"
                    @change="update_director(supervisor, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">e) </v-col>
                <v-col cols="11"> Scan copy of Passport </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                    clear-icon=""
                      v-model="supervisor.passport_image"
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click here to upload scan copy of Passport"
                      :rules="uploadRules2"
                      @change="update_director(supervisor, -1)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12">
                    <v-img
                      v-if="supervisor.passport_filetype === 'image'"
                      :src="supervisor.passport"
                      @click="show_image(supervisor.passport)"
                    ></v-img>
                    <div
                      v-if="supervisor.passport_filetype === 'pdf'"
                      @click="show_pdf(supervisor.passport)"
                    >
                      <pdf
                        :src="supervisor.passport"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>
          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">f) </v-col>
                <v-col cols="11"> A Photo holding his/her passport </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                    clear-icon=""
                      v-model="supervisor.photo_image"
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click here to upload A Photo holding his/her passport"
                      :rules="uploadRules2"
                      @change="update_director(supervisor, -1)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12">
                    <v-img
                      v-if="supervisor.photo_filetype === 'image'"
                      :src="supervisor.photo"
                      @click="show_image(supervisor.photo)"
                    ></v-img>
                    <div
                      v-if="supervisor.photo_filetype === 'pdf'"
                      @click="show_pdf(supervisor.photo)"
                    >
                      <pdf :src="supervisor.photo" width="100%" height="100%" />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-spacer></v-spacer>
              <span class="body-2 font-italic">click</span>
              <span class="mx-1 body-2 text-decoration-underline blue--text pointer"  @click.stop="show_sample" >
                here
              </span>
              <span class="body-2 font-italic">
                to see the sample of photo of holding the passport.
              </span>
                
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>

        <router-link to="/pa/wfoe_directors" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/pa/gm" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";
import PANotifyDlg from "@/components/PANotifyDlg.vue";
import RespNoteDlg from "@/components/RespNoteDlg.vue";

import pdf from "vue-pdf";

export default {
  components: {
    Progressbar,
    CertImgDlg,
    pdf,
    NoteDlg,
    PANotifyDlg,
    RespNoteDlg,
  },

  mounted: function () {
    this.$refs.progress1.bk3 = "/todo2.png";
    this.$refs.progress1.txt3 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_wfoe_supervisor();
  },

  data() {
    return {
      supervisor: {},

      uploadRules2: [
        (file) => {
          if (file) {
            return (
              file.size < 10000000 || "file size should be less than 10 MB!"
            );
          } else {
            return true;
          }
        },
      ],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_wfoe_supervisor() {
      console.log("reload_wfoe_supervisor");
      let that = this;
      this.$http
        .get("/pa/get_wfoe_supervisor")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.supervisor = response.data.supervisor;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_director(director, idx) {
      console.log("update_director..");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_wfoe_supervisor();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_wfoe_supervisor();
        return;
      }

      let validation = false;
      if (idx == -1) {
        validation = this.$refs.xform.validate();
      } else {
        validation = this.$refs.form[idx].validate();
      }

      if (validation) {
        let formData = new FormData();
        let that = this;
        if (director.passport_image) {
          formData.append("passport", director.passport_image);
        }
        if (director.id) {
          formData.append("director_id", director.id);
        }

        formData.append("name", director.name);
        formData.append("passport_no", director.passport_no);

        if (director.photo_image) {
          formData.append("photo", director.photo_image);
        }
        formData.append("email", director.email);
        formData.append("phone_number", director.phone_number);

        this.$http
          .post("/pa/update_wfoe_director", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_wfoe_supervisor();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false;
        this.$refs.noteDlg.text =
          "Please confirm the name should be identical with his/her passport.";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false;
        this.$refs.noteDlg.text =
          "Please upload the colorful scan copy of passport in A4 (210mm * 297mm) format, with 1:1 proportion in PDF version.";
      } 

      this.$refs.noteDlg.dialog = true;
    },

    show_resp() {
      this.$refs.respNoteDlg.title = "Responsibility of Supervisor";
      this.$refs.respNoteDlg.text =
        "A supervisor shall exercise the following duties and powers: <br/> \
        (1) inspect the company finances; <br/> \
        (2) supervise the performance of duties by directors and senior management personnel and propose to remove a director or senior executive who violates the provision of the laws and administrative regulations and the articles of association of the company or the resolutions of the board of shareholders; <br/> \
        (3) require a director or senior executive who acts against the interests of the company to make correction; <br/> \
        (4) propose to convene ad hoc shareholders' meeting, convene and chair a shareholders' meeting when the board of directors fails to convene and chair a shareholders' meeting in accordance with the provisions of this Law; <br/> \
        (5) make proposals at shareholders' meetings; <br/> \
        (6) file a lawsuit against a director or senior executive in accordance with the provisions of Article 151; and <br/> \
        (7) other duties and powers stipulated in the articles of association of the company.";

      this.$refs.respNoteDlg.dialog = true;
    },

    show_sample() {
      this.$refs.certImgDlg.url = require("@/assets/sample-photo.jpeg");
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>