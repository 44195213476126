import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/assets/css/global.css";
import Axios from 'axios'

let baseURL = process.env.VUE_APP_BASE_URL

console.log('baseURL is ::')

console.log(baseURL)

Vue.prototype.$http = Axios;
Vue.prototype.$EventBus = new Vue();
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
  if (typeof baseURL !== 'undefined') {
    Vue.prototype.$http.defaults.baseURL = baseURL;
    console.log('set $http to:' + baseURL );
  }
}


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)


