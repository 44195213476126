<template>
  <div class="business_scope">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link
        to="/wfoe/name"
        class="px-8 pointer"
        tag="div"
        ><span class=""> Name </span></router-link
      >

      <router-link to="/wfoe/capital" class="px-8 pointer" tag="div"
        ><span class=""> Capital</span></router-link
      >
      <router-link to="/wfoe/business_scope" class="px-8 pointer font-weight-bold subtitle-1" tag="div"
        ><span class="text-decoration-underline blue--text"> Business Scope </span></router-link
      >
      <router-link to="/wfoe/registered_address" class="px-8 pointer" tag="div"
        ><span class="">Registered Address </span></router-link
      >
      <router-link to="/wfoe/operational_period" class="px-8 pointer" tag="div"
        ><span class=""> Other Information </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <note-dlg ref="noteDlg" />

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card>
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">Business Scope</h3>

          <v-spacer></v-spacer>
        </v-card-title>

        <v-form ref="form">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1"> a) </v-col>
                <v-col cols="11"> Business Scope </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-textarea
                  outlined
                    v-model="wfoe.business_scope"
                    @change="update_wfoe"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1"> b) </v-col>
                <v-col cols="11"> Comparable Companies (Optional) </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-textarea
                  outlined
                    v-model="wfoe.comparable_companies"
                    @change="update_wfoe"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-form>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <router-link to="/wfoe/capital" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/wfoe/registered_address" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import NoteDlg from "@/components/NoteDlg.vue";

export default {
  components: { Progressbar, NoteDlg },

  mounted: function () {
    this.$refs.progress1.bk2 = "/todo2.png";
    this.$refs.progress1.txt2 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_wfoe();
  },

  data() {
    return {
      wfoe: {
        id: "",
      },
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    reload_wfoe() {
      console.log("reload_wfoe");
      let that = this;
      this.$http
        .get("/wfoe/get_wfoe")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.wfoe = response.data.wfoe;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_wfoe() {
      console.log("update_wfoe...");
      if (this.userRole == 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Agent cannot modify client FDI Information!"
            );
            this.reload_wfoe();

            return
      }

      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_wfoe();  
        return;
      }
      if (this.$refs["form"]) {
        let formData = new FormData();
        let that = this;

        formData.append("wfoe_id", this.wfoe.id);
        formData.append("business_scope", this.wfoe.business_scope);
        formData.append("comparable_companies", this.wfoe.comparable_companies);
        formData.append("update_flag", "business_scope");

        this.$http
          .post("/wfoe/update_wfoe", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (resp) {
            if (resp.data.status == 'success'){
            console.log("SUCCESS!");
            that.reload_wfoe();
              } else {
                  that.$EventBus.$emit("toast", resp.data.msg);
              }
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please provide the overview of WFOE’s proposed business scope in English first and RSM will polish the wording into the required descriptions in CN words according to authorities' common requirements. Please note that AMR requires to use some standard wordings and thus business scope will be finally approved or revised by AMR. ";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text = 
          "You could provide us some comparable companies registered in China and then RSM could provide the business scopes description for comparable companies for your reference."
      }
      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>