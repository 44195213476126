<template>
    <nav>

        <v-snackbar v-model="snackbar" :timeout="3000" top color="warning">
            <v-row class="mx-auto">
            <span class="font-weight-bold ml-2">{{toast}}</span>
            <v-spacer></v-spacer>
            <span class="pointer" @click.stop="snackbar = false"
          ><v-icon class="white--text mr-n4">mdi-close-circle</v-icon></span
        >
            </v-row>
            
        </v-snackbar>
        <v-app-bar app v-if="isLoggedIn">
            <v-img src="@/assets/logo@2x.png" height="35px" max-width="80px"/>
            <span class="ml-8">{{activeClient}}</span>

            <v-chip v-if="fdiStatus" class="mx-4" color="blue lighten-1" text-color="white">
          {{ fdiStatus }}
        </v-chip>
            <v-spacer></v-spacer>

            <span>{{username}}</span>
            <v-btn v-if="isLoggedIn" text color="grey" @click="logout">
            <span>Sign Out</span>
            <v-icon right>mdi-exit-to-app</v-icon>
            </v-btn>

        </v-app-bar>
       
    </nav>
</template>
<script>
export default {
     computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      activeClient: function() {
          return this.$store.getters.activeClient;
      },
      fdiStatus: function() {
          return this.$store.getters.fdiStatus;
      },
      username: function() {
          return this.$store.getters.username;
      }
    },

        mounted(){
            this.$EventBus.$on('toast',(msg)=>{
                this.snackbar = true;
                this.toast = msg;
            })
        },

    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
    },
    
    data() {
        return {
            links: [
                { icon: 'dashboard', text: 'Dashboard', route: '/'},
                { icon: 'folder', text: 'My Projects', route: '/projects' },
                { icon: 'account-multiple', text: 'Team', route: 'team'}
            ],
            snackbar: false,
            toast:''
        }
    }
    
}
</script>