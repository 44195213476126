<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="12" class="col1">
        <v-row no-gutters>
          <v-col cols="3" class="">
            <v-card flat to="/">
              <v-img :src="bk1" contain :class="`${txt1}`">
                <v-card-text :class="`${diplayClass} pl-10`">
                  <div>Investor Information</div>
                </v-card-text>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="3" class="">
            <v-card flat to="/wfoe/name" >
              <v-img :src="bk2"  contain :class="`${txt2}`">
                <v-card-text :class="`${diplayClass} pl-10`">
                
                  <div>China WFOE Information</div>
                </v-card-text>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="3" class="">
            <v-card flat to="/pa/wfoe_directors">
              <v-img :src="bk3"  contain :class="`${txt3}`">
                <v-card-text :class="`${diplayClass} pl-10`">
                  <div>Position Arrangement</div>
                </v-card-text>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="3" class="">
            <v-card flat to="/summary" >
              <v-img :src="bk4"  contain :class="`${txt4}`">
                <v-card-text :class="`${diplayClass} pl-10`">
                  <div>Summary</div>
                </v-card-text>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <div v-if="userRole == 'client'">
        <span>click</span>
        <a :href="url" download="" class="mx-1"> here </a>
        <span> see IRL</span>
      </div>
    </v-row>
    <v-row v-if="userRole == 'staff'">
      <v-spacer></v-spacer>
      <a href="/clients" class="mx-1"> 客户列表 </a>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      bk1: "/todo1.png",
      bk2: "/todo1.png",
      bk3: "/todo1.png",
      bk4: "/todo1.png",
      txt1: "",
      txt2: "",
      txt3: "",
      txt4: "",
      irl_dialog: false,
      url: process.env.VUE_APP_BASE_URL + "/system/download_irl/",
    };
  },
  methods: {
    openIRL() {
      this.irl_dialog = true;
    },
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "caption";
          break;
        case "sm":
          classString = "body-2";
          break;
        case "md":
          classString = "body-1";
          break;
        case "lg":
          classString = "body-1";
          break;
        case "xl":
          classString = "body-1";
          break;
      }
      return classString;
    },
  },
};
</script>
