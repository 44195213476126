<template>
      <v-dialog max-width="500px"
        v-model="dialog"
      >

        <v-card>

         <v-card-title>
             <div class="blue--text"> Confirm DELETE Client [<span class="error--text">{{client.company_name}}</span>] ? </div>
         </v-card-title>
            
          <v-card-actions>
              <v-spacer></v-spacer>
              <a href="#"  class="mx-6" @click="delete_client">Confirm Delete!</a>
              <a href="#"  class="px-2" @click="dialog=false">Dismiss</a> 
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    data () {
        return {
            dialog: false,
            client:{}
        }
    },
    methods: {
        close_client() {
        let that = this
        this.$http.post('/system/close_client/' + this.client.id)
        .then(function (response) {
            console.log(response);
            if (response.data.status == 'success') {
                console.log('SUCCESS!');
                that.dialog = false;
                that.$emit('delete-client')
                that.$EventBus.$emit('toast', 'Delete Client ' + that.client.name + ' successfully!')
            } else {
                that.$store.dispatch('logout')
                that.$router.push('login')
            }
        })
        .catch(function (error) {
            console.log(error);
        });
        },
    }
}
</script>
