<template>
  <v-dialog 
    v-model="pa_notify" 
    max-width="600px"
    persistent
    >
    <v-card>
        <v-card-title>
            <span>Note</span> 
             <v-spacer></v-spacer>
        <span class="pointer mr-n4 mt-n6" @click.stop="pa_notify=false"
          ><v-icon class="red--text">mdi-close-circle</v-icon></span
        >
        </v-card-title>
        
        <v-divider></v-divider>
      <v-card-text class="ma-4 font-italic">
        <div class="ma-2">
          <span class="title"> &bull;</span>	 &nbsp; &nbsp; &nbsp; The newly-established China entity needs at least one legal representative, one executive director, one supervisor, one general manager and one finance controller (5 positions in total). Legal representative MUST be either executive director or general manager, while the above three positions could also be the same person. In this regards, you should at least arrange three different personnel in the first place.
        </div>
        <div class="ma-2">
            <span class="title"> &bull;</span>	 &nbsp; &nbsp; &nbsp; All managements could be foreigners. For requirements on the original passports, authorities from different places may hold different practice. The original passport of legal representative usually shall be presented to State Administration for Market Regulation (AMR) for business license (this requirement could be replaced by the notarization and authentication document of legal representative’s passport in many places) while for other managements (such as supervisor, finance controller), colorful scan copies of the passports with 1:1 proportion could be acceptable.
        </div>
        <div class="ma-2">
           <span class="title"> &bull;</span>	 &nbsp; &nbsp; &nbsp; All managements do not need to be based in China, but legal representative may come to China serval times for authority application as needed (e.g. bank account opening and real-name authentication in tax record) and finance controller is suggested to come to China once for real-name authentication in tax record.
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <a href="#" class="px-2" @click="dialog = false">Close</a>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      pa_notify: true,
    };
  },

      created() {
        if (this.paShowed) {
            this.pa_notify = false
        } else {
            this.pa_notify = true
        }
        this.$store.dispatch('show_pa')
        
    },

  computed: {
    paShowed () {
        return this.$store.getters.paShowed
    }
    }
    
};
</script>
