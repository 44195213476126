<template>
  <div class="wfoe_directors">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link
        to="/pa/wfoe_directors"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Director </span></router-link
      >

      <router-link to="/pa/supervisor" class="px-8 pointer" tag="div"
        ><span class=""> Supervisor </span></router-link
      >
      <router-link to="/pa/gm" class="px-8 pointer" tag="div"
        ><span class=""> General Manager </span></router-link
      >
      <router-link to="/pa/legal_repr" class="px-8 pointer" tag="div"
        ><span class=""> Legal Representative </span></router-link
      >
      <router-link to="/pa/finance_controller" class="px-8 pointer" tag="div"
        ><span class=""> Finance Controller </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <resp-note-dlg ref="respNoteDlg" />
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />
      <PANotifyDlg />

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card class="mb-4">
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Director of China WFOE
          </h3>

          <v-spacer></v-spacer>
          <!-- <span>
              Click here to Know the Responsibility of Director 
          </span> -->
          <span class="body-2 font-italic">click</span>
          <a class="mx-1 body-2 font-italic text-decoration-underline" @click.stop="show_resp()"> here </a>
          <span class="body-2 font-italic">
            to know the Responsibility of Director
          </span>
        </v-card-title>

        <v-form ref="paform">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1">a) </v-col>
                <v-col cols="11">Setup Board of Directors</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-radio-group
                    v-model="pa_info.pa_type"
                    @change="update_painfo"
                  >
                    <v-radio
                      key="1"
                      label="Yes"
                      value="board_of_directors"
                    ></v-radio>
                    <v-radio
                      key="2"
                      label="No"
                      value="executive_director_only"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card>

      <v-card v-if="pa_info.pa_type == 'executive_director_only'" class="mb-4">
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Executive Director
          </h3>
        </v-card-title>

        <v-form ref="xform">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters >
                <v-col cols="1">a) </v-col>
                <v-col cols="11">Name</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                  outlined
                    dense
                    v-model="executive_director.name"
                    @change="update_director(executive_director, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">b)</v-col>
                <v-col cols="11"> Passport No </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                  outlined
                    dense
                    v-model="executive_director.passport_no"
                    @change="update_director(executive_director, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">c) </v-col>
                <v-col cols="11">Phone Number</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <v-text-field
                  outlined
                    dense
                    v-model="executive_director.phone_number"
                    @change="update_director(executive_director, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">d) </v-col>
                <v-col cols="11">Email</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="executive_director.email"
                    @change="update_director(executive_director, -1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">e) </v-col>
                <v-col cols="11"> Scan copy of Passport </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                    clear-icon=""
                      v-model="executive_director.passport_image"
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click here to upload scan copy of Passport"
                      :rules="uploadRules2"
                      @change="update_director(executive_director, -1)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12">
                    <v-img
                      v-if="executive_director.passport_filetype === 'image'"
                      :src="executive_director.passport"
                      @click="show_image(executive_director.passport)"
                    ></v-img>
                    <div
                      v-if="executive_director.passport_filetype === 'pdf'"
                      @click="show_pdf(executive_director.passport)"
                    >
                      <pdf
                        :src="executive_director.passport"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(3)"
              >
                &lt; Note 3 &gt;</span
              >
            </v-col>
          </v-row>
          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">f) </v-col>
                <v-col cols="11"> A Photo holding his/her passport </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                    outlined
                    clear-icon=""
                      v-model="executive_director.photo_image"
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click here to upload A Photo holding his/her passport"
                      :rules="uploadRules2"
                      @change="update_director(executive_director, -1)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12">
                    <v-img
                      v-if="executive_director.photo_filetype === 'image'"
                      :src="executive_director.photo"
                      @click="show_image(executive_director.photo)"
                    ></v-img>
                    <div
                      v-if="executive_director.photo_filetype === 'pdf'"
                      @click="show_pdf(executive_director.photo)"
                    >
                      <pdf
                        :src="executive_director.photo"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-spacer></v-spacer>
              <span class="body-2 font-italic">click</span>
              <span class="mx-1 body-2 text-decoration-underline blue--text pointer"  @click.stop="show_sample" >
                here
              </span>
              <span class="body-2 font-italic">
                to see the sample of photo of holding the passport.
              </span>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card>
      <div v-if="pa_info.pa_type == 'board_of_directors'">
        <v-card
          class="mb-4"
          v-for="(director, idx) in wfoe_directors"
          :key="director.id"
        >
          <v-card-title>
            <h3 class="title text-decoration-underline blue--text">
              Member of the Board {{ idx + 1 }}
            </h3>
            <v-spacer></v-spacer>
            <span
              class="pointer mr-n3 mt-n8"
              @click.stop="delete_wfoe_director(director)"
              ><v-icon class="red--text">mdi-close-circle</v-icon></span
            >
          </v-card-title>

          <v-form ref="form">
            <v-row class="px-3">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">a) </v-col>
                  <v-col cols="11">Name</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"></v-col>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      v-model="director.name"
                      @change="update_director(director, idx)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
            </v-row>

            <v-row class="px-3">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">b)</v-col>
                  <v-col cols="11"> Passport No </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"></v-col>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      v-model="director.passport_no"
                      @change="update_director(director, idx)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="px-3">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">c) </v-col>
                  <v-col cols="11">Phone Number</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"> </v-col>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      v-model="director.phone_number"
                      @change="update_director(director, idx)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="px-3">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">d) </v-col>
                  <v-col cols="11">Email</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"></v-col>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      v-model="director.email"
                      @change="update_director(director, idx)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="px-3 pb-4">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">e) </v-col>
                  <v-col cols="11"> Scan copy of Passport </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"> </v-col>
                  <v-col cols="11">
                    <div>
                      <v-file-input
                      outlined
                      clear-icon=""
                        v-model="director.passport_image"
                        accept="application/pdf"
                        hint="accept pdf files"
                        label="click here to upload scan copy of Passport"
                        :rules="uploadRules2"
                        @change="update_director(director, idx)"
                      >
                      </v-file-input>
                    </div>
                    <div class="mx-12 px-12">
                      <v-img
                        v-if="director.passport_filetype === 'image'"
                        :src="director.passport"
                        @click="show_image(director.passport)"
                      ></v-img>
                      <div
                        v-if="director.passport_filetype === 'pdf'"
                        @click="show_pdf(director.passport)"
                      >
                        <pdf
                          :src="director.passport"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="2">
                <v-spacer></v-spacer>
                <span
                  class="
                    pointer
                    font-italic
                    text-decoration-underline
                    blue--text
                  "
                  @click.stop="showNote(3)"
                >
                  &lt; Note 3 &gt;</span
                >
              </v-col>
            </v-row>
            <v-row class="px-3 pb-4">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">f) </v-col>
                  <v-col cols="11"> A Photo holding his/her passport </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"> </v-col>
                  <v-col cols="11">
                    <div>
                      <v-file-input
                      outlined
                      clear-icon=""
                        v-model="director.photo_image"
                        accept="application/pdf"
                        hint="accept pdf files"
                        label="click here to upload A Photo holding his/her passport"
                        :rules="uploadRules2"
                        @change="update_director(director, idx)"
                      >
                      </v-file-input>
                    </div>
                    <div class="mx-12 px-12">
                      <v-img
                        v-if="director.photo_filetype === 'image'"
                        :src="director.photo"
                        @click="show_image(director.photo)"
                      ></v-img>
                      <div
                        v-if="director.photo_filetype === 'pdf'"
                        @click="show_pdf(director.photo)"
                      >
                        <pdf :src="director.photo" width="100%" height="100%" />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-spacer></v-spacer>
                <span class="body-2 font-italic">click</span>
              <span class="mx-1 body-2 text-decoration-underline blue--text pointer"  @click.stop="show_sample" >
                here
              </span>
              <span class="body-2 font-italic">
                to see the sample of photo of holding the passport.
              </span>
                
              </v-col>
              
            </v-row>

            <v-row class="px-3">
              <v-col cols="1"> </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="1">g) </v-col>
                  <v-col cols="11">Chairman of the Board</v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1"></v-col>
                  <v-col cols="11">
                    <v-radio-group
                      v-model="director.is_chairman"
                      @change="update_chairman(director)"
                    >
                      <v-radio key="1" label="Yes" value="yes"></v-radio>
                      <v-radio key="2" label="No" value="no"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
        </v-card>
      </div>

      <v-row class="ma-4">
        <div v-if="pa_info.pa_type == 'board_of_directors'">
          <span
            class="text-decoration-underline blue--text pointer"
            @click.stop="addNewDirector"
            >Add A New Director</span
          >
        </div>
        <v-spacer></v-spacer>

        <router-link to="/wfoe/operational_period" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/pa/supervisor" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";
import PANotifyDlg from "@/components/PANotifyDlg.vue";
import RespNoteDlg from '@/components/RespNoteDlg.vue';

import pdf from "vue-pdf";

export default {
  components: { Progressbar, CertImgDlg, pdf, NoteDlg, PANotifyDlg, RespNoteDlg},

  mounted: function () {
    this.$refs.progress1.bk3 = "/todo2.png";
    this.$refs.progress1.txt3 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.get_pa_info();
    this.reload_wfoe_directors();
  },

  data() {
    return {
      wfoe_directors: [],
      pa_info: {},
      executive_director: {},
      url: process.env.VUE_APP_BASE_URL + "/system/download_resp_of_director/",

      uploadRules2: [
        (file) => {
          if (file) {
            return file.size < 10000000 || "file size should be less than 10 MB!";
          } else {
            return true;
          }
        },
      ],
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    get_pa_info() {
      console.log("get_pa_info");
      let that = this;
      this.$http
        .get("/pa/get_pa_info")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.pa_info = response.data.pa_info;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    reload_wfoe_directors() {
      console.log("reload_wfoe_directors");
      let that = this;
      this.$http
        .get("/pa/get_wfoe_directors")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.wfoe_directors = response.data.directors;
            that.executive_director = response.data.executive_director;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_chairman(director) {
      console.log("update_chairman");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();
        return;
      }
      if (director.is_chairman == "yes") {
        for (var idx = 0; idx < this.wfoe_directors.length; idx++) {
          this.wfoe_directors[idx].is_chairman = "no";
        }
        director.is_chairman = "yes";
      }

      var chairman_id = -1;

      for (idx = 0; idx < this.wfoe_directors.length; idx++) {
        if (this.wfoe_directors[idx].is_chairman == "yes") {
          chairman_id = this.wfoe_directors[idx].id;
        }
      }

      let formData = new FormData();
      let that = this;

      if (this.pa_info.id) {
        formData.append("pa_id", this.pa_info.id);
      }
      formData.append("chairman", chairman_id);
      this.$http
        .post("/pa/update_pa_info", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          console.log("SUCCESS!");
          that.reload_wfoe_directors();
        })
        .catch(function () {
          console.log("FAILURE!!");
        })
        .then(function () {
          console.log("then...");
        });
    },

    addNewDirector() {
      console.log("add New Director");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();
        return;
      }
      let that = this;
      this.$http
        .get("/pa/add_new_wfoe_director")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.reload_wfoe_directors();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_director(director, idx) {
      console.log("update_director..");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();
        return;
      }

      let validation = false;
      if (idx == -1) {
        validation = this.$refs.xform.validate();
      } else {
        validation = this.$refs.form[idx].validate();
      }

      if (validation) {
        let formData = new FormData();
        let that = this;
        if (director.passport_image) {
          formData.append("passport", director.passport_image);
        }
        if (director.id) {
          formData.append("director_id", director.id);
        }
          formData.append("name", director.name);
          formData.append("passport_no", director.passport_no);
        if (director.photo_image) {
          formData.append("photo", director.photo_image);
        }
          formData.append("email", director.email);
          formData.append("phone_number", director.phone_number);
        this.$http
          .post("/pa/update_wfoe_director", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_wfoe_directors();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },
    delete_wfoe_director(director) {
      let that = this;
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();
        return;
      }
      this.$http
        .post("/pa/delete_wfoe_director/" + director.id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.dialog = false;
            that.$EventBus.$emit(
              "toast",
              "Delete WFOE " + director.name + " successfully!"
            );
            that.reload_wfoe_directors();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    update_painfo() {
      console.log("update_painfo...");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();

        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.get_pa_info();
        this.reload_wfoe_directors();
        return;
      }
      if (this.$refs.paform.validate()) {
        let formData = new FormData();
        let that = this;

        if (this.pa_info.id) {
          formData.append("pa_id", this.pa_info.id);
        }
        formData.append("pa_type", this.pa_info.pa_type);
        this.$http
          .post("/pa/update_pa_info", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.get_pa_info();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "The investor could choose to appoint an Executive Director or set up the Board of Directors as needed basis. Commonly, the number of the Directors shall be odd (3 to 13, with 3 as the commonest) and please indicate the Chairman of the Board.";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please confirm the name should be identical with his/her passport. ";
      } else if (idx == 3) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please upload the colorful scan copy of passport in A4 (210mm * 297mm) format, with 1:1 proportion in PDF version.";
      } 


      this.$refs.noteDlg.dialog = true;
    },

    show_resp() {
      this.$refs.respNoteDlg.title = "Responsibility of Director"
      this.$refs.respNoteDlg.text = 
      "<b>The board of directors / an executive director shall be accountable to the board of shareholders and shall exercise the following duties and powers: </b> <br/>\
      (1) convene shareholders' meetings and report to the board of shareholders; <br/>\
      (2) execute the resolutions passed by the board of shareholders; <br/>\
      (3) decide on the business plans and investment schemes of the company; <br/>\
      (4) formulate the annual financial budget and financial accounting plan of the company; <br/>\
      (5) formulate the profit distribution plan and loss recovery plan of the company; <br/>\
      (6) formulate the plan for increase or reduction of registered capital and issue of corporate bonds; <br/>\
      (7) formulate the plan for merger, division, dissolution or change of company structure; <br/>\
      (8) decide on the set-up of internal management organization of the company; <br/>\
      (9) decide on appointment or dismissal of company managers and their remuneration, and decide on appointment or dismissal of deputy managers and finance controller of the company based on the nomination by the managers. <br/>\
      (10) formulate the basic management system of the company; and <br/>\
      (11) other duties and powers stipulated by the articles of association of the company. \
      The board of directors / an executive director shall bear compensation liability if violating the provisions of laws and administrative regulations or the articles of association of the company in his/her performance of duties and powers and causing the company to suffer damages."

      this.$refs.respNoteDlg.dialog = true
    },

    show_sample() {
      this.$refs.certImgDlg.url = require("@/assets/sample-photo.jpeg");
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },


  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>