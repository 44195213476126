<template>
      <v-dialog
        v-model="wfoe_notify"
        max-width="800px"
        persistent
      >

        <v-card>
          <v-card-title v-if="title" class="blue--text headline">
            <h3>{{title}} </h3>
          </v-card-title>

          <v-divider></v-divider>

         <v-img :src="url" contain
            :height="img_height"
            :max-width="img_width"
            class="mt-5"
            ></v-img>
  
          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <a v-if="page===2" href="#"  class="px-2" @click="prev_img">Prev</a> 
              <a v-if="page===1" href="#"  class="px-2" @click="next_img">Next</a> 
              <a href="#"  class="px-2" @click="wfoe_notify=false">Close</a> 
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    data () {
        return {
        wfoe_notify: true,
        url:"wfoe-notify2.png",
        img_height:478,
        img_width:800,
        page:1,
        align_img:'',
        title:''
        
        }
    },

    created() {
        console.log('dlg created')
        console.log(this.wfoeShowed)
        if (this.wfoeShowed) {
            this.wfoe_notify = false
        } else {
            this.wfoe_notify = true
        }
        this.$store.dispatch('show_wfoe')
        
    },

      methods: {
          next_img() {
              this.url = 'irs.png',
              this.img_height = 400
              this.img_width = 800
              this.page = 2,
              this.align_img = 'justify-center'
              this.title = 'Information Required Summary'
          },
          prev_img() {
              this.url = 'wfoe-notify2.png',
              this.img_height = 478
              this.img_width = 800
              this.page = 1
              this.align_img = 'justify-center'
              this.title = ""
          }

  },

  computed: {
    wfoeShowed () {
        console.log('compute wfoeShowed')
        console.log(this.$store.getters.wfoeShowed)
        return this.$store.getters.wfoeShowed
    }
    }
    
    

}
</script>
