<template>
  <div class="wfoe_capital">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link to="/wfoe/name" class="px-8 pointer" tag="div"
        ><span class="w"> Name </span></router-link
      >

      <router-link
        to="/wfoe/capital"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Capital</span></router-link
      >
      <router-link to="/wfoe/business_scope" class="px-8 pointer" tag="div"
        ><span class=""> Business Scope </span></router-link
      >
      <router-link to="/wfoe/registered_address" class="px-8 pointer" tag="div"
        ><span class="">Registered Address </span></router-link
      >
      <router-link to="/wfoe/operational_period" class="px-8 pointer" tag="div"
        ><span class=""> Other Information </span></router-link
      >
    </v-toolbar>
    <note-dlg ref="noteDlg" />
    <v-row class="ma-4">
      

      <v-spacer></v-spacer>

    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card>
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">Capital</h3>

          <v-spacer></v-spacer>
        </v-card-title>

        <v-form ref="form">

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="1"> a) </v-col>
                <v-col cols="11"> Method of Capital Contribution  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="wfoe.capital_contrib_method"
                    @change="update_wfoe"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1"> b) </v-col>
                <v-col cols="11"> Registered Capital Currency </v-col>
              </v-row>
              <v-row no-gutters class="align-baseline">
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-combobox
                    v-model="wfoe.registered_capital_currency"
                    :items="currencies"
                    item-text="name"
                    item-value="name"
                    placeholder="Currency"
                    outlined
                    :rules="[rules.cur(currencies)]"
                    @change="update_wfoe"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1"> </v-col>
                <v-col cols="11"> Registered Capital Amount </v-col>
              </v-row>
              <v-row no-gutters class="align-baseline">
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-currency-input
                    dense
                    outlined
                    v-model="wfoe.registered_capital"
                    :options="options1"
                    @keydown="update_wfoe"
                    @change="update_wfoe"
                  ></v-currency-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1"> c) </v-col>
                <v-col cols="11"> Total Investment Currency </v-col>
              </v-row>
              <v-row no-gutters class="align-baseline">
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-combobox
                    v-model="wfoe.total_investment_currency"
                    :items="currencies"
                    item-text="name"
                    item-value="name"
                    placeholder="Currency"
                    @change="update_wfoe"
                    outlined
                    :rules="[rules.cur(currencies)]"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(3)"
              >
                &lt; Note 3 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1"> </v-col>
                <v-col cols="11"> Total Investment Amount </v-col>
              </v-row>
              <v-row no-gutters class="align-baseline">
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-currency-input
                    dense
                    outlined
                    v-model="wfoe.total_investment"
                    :options="options2"
                    @keydown="update_wfoe"
                    @change="update_wfoe"
                  ></v-currency-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1"> d) </v-col>
                <v-col cols="11"> Proposed deadline of the capital contribution  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-date-picker
                    v-model="wfoe.capital_contrib_deadline"
                    @change="update_wfoe"
                  ></v-date-picker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(4)"
              >
                &lt; Note 4 &gt;</span
              >
            </v-col>
          </v-row>


          <v-divider></v-divider>
        </v-form>
      </v-card>

      <v-row class="ma-4">
        <v-spacer></v-spacer>
        <router-link to="/wfoe/name" tag="div">
          <span class="text-decoration-underline blue--text pointer">Prev</span>
        </router-link>
        <router-link to="/wfoe/business_scope" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import NoteDlg from "@/components/NoteDlg.vue";
import VCurrencyInput from "@/components/VCurrencyInput.vue";


export default {
  components: { Progressbar, NoteDlg, VCurrencyInput},

  mounted: function () {
    this.$refs.progress1.bk2 = "/todo2.png";
    this.$refs.progress1.txt2 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID

    this.get_currency_list();
    this.reload_wfoe();
  },

  data() {
    return {
      wfoe: {'id':''},
      currencies: [],
      rules: {
        cur(names) {
          return (v) => {
            if (names.length > 0) {
              if (v) {
                console.log("validate:" + names);
                return names.includes(v) || "Invald Choice";
              } else {
                return true;
              }
            } else {
              return true;
            }
          };
        },
      },
    };
  },

  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
    options1() {
      if (this.currencies.includes(this.wfoe.registered_capital_currency)) {
        return {
          currency: this.wfoe.registered_capital_currency,
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: false,
          valueRange: { min: 0, max: 1e12 },
        };
      } else {
        return {
          currency: "USD",
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: false,
          valueRange: { min: 0, max: 1e12 },
        };
      }
    },
    options2() {
      if (this.currencies.includes(this.wfoe.total_investment_currency)) {
        return {
          currency: this.wfoe.total_investment_currency,
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: false,
          valueRange: { min: 0, max: 1e12 },
        };
      } else {
        return {
          currency: "USD",
          hideCurrencySymbolOnFocus: false,
          hideGroupingSeparatorOnFocus: false,
          valueRange: { min: 0, max: 1e12 },
        };
      }
    },
    today() {
      return new Date();
    }
  },
  methods: {
    get_currency_list() {
      console.log("get_currency_list");
      let that = this;
      this.$http
        .get("/wfoe/get_currency_list")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.currencies = response.data.currencies;
          } else {
            that.$EventBus.$emit("toast", response.data.msg);
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
    reload_wfoe() {
      console.log("reload_wfoe");
      let that = this;
      this.$http
        .get("/wfoe/get_wfoe")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.wfoe = response.data.wfoe;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_wfoe() {
      console.log("update_wfoe>>>");
      if (this.userRole == 'staff') {
        this.$EventBus.$emit(
              "toast",
              "Agent cannot modify client FDI Information!"
            );

            return
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        return;
      }
      if (this.$refs["form"]) {
        let formData = new FormData();

        formData.append("wfoe_id", this.wfoe.id);
        formData.append("method", this.wfoe.capital_contrib_method)
        formData.append("registered_capital", this.wfoe.registered_capital);
        formData.append('deadline',this.wfoe.capital_contrib_deadline)
        formData.append(
          "registered_capital_currency",
          this.wfoe.registered_capital_currency
        );
        formData.append("total_investment", this.wfoe.total_investment);
        formData.append(
          "total_investment_currency",
          this.wfoe.total_investment_currency
        );
        formData.append("update_flag", "capital");

        this.$http
          .post("/wfoe/update_wfoe", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (resp) {
            if (resp.data.status == "success") {
              console.log("SUCCESS!");
              //that.reload_wfoe();
            } else {
              //that.$EventBus.$emit("toast", resp.data.msg);

              console.log("fail");
              console.log(resp.data)
            //    that.$EventBus.$emit("toast", resp.data.msg);
            // that.$store.dispatch("logout");
            // that.$router.push("login");
            }
          })
          .catch(function () {
            console.log("FAILURE!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Suggest to use cash. The direct shareholder may make capital contribution in cash or in kind, intellectual property, land use rights or any other non-cash property which can be valuated and transferred in accordance with the law, except for properties prohibited by laws and administrative regulations to be used for capital contribution. Non-cash properties used for capital contribution shall be valuated and verified and shall not be overvalued or undervalued. Where there are provisions in the laws and administrative regulations on valuation, such provisions shall prevail.";
          
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "There is no legal limitation on the amount of registered capital and just make sure it is enough for the proposed business activities at the early stage of establishment. [Unless otherwise provided in laws, administrative regulations and State Council's decisions for particular industries.] No less than RMB 1,000,000/USD 156,000 is recommended in practice. ";
      } else if (idx == 3) {
        this.$refs.noteDlg.text =
          "The ratio between the registered capital and its total investment shall comply with the following provisions:";
        this.$refs.noteDlg.show_img = true
      } else if (idx == 4) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "There is no need to inject the full amount of registered capital once but please confirm the currency should be same with currency actually injected and the total amount actually injected by several times should be same as it stated on newly-established China entity’s business license. There is no legal limitation on the injected deadline of registered capital while we usually suggest the full amount should be injected within two years after the issuance of newly-established China entity’s business license.";
      }
      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>