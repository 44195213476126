<template>
  <div class="actualcontrollers">
    <Progressbar ref="progress1" class="hidden-sm-and-down" />
    <v-divider></v-divider>
    <v-toolbar color="" flat class="lighten-1">
      <router-link
        to="/"
        class="px-8 pointer font-weight-bold subtitle-1"
        tag="div"
        ><span class="text-decoration-underline blue--text"> Ultimate Beneficial Owner </span></router-link
      >

      <router-link to="/direct_shareholders" class="px-8 pointer" tag="div"
        ><span class=""> Direct Shareholder </span></router-link
      >
      <router-link to="/directors" class="px-8 pointer" tag="div"
        ><span class=""> Board Resolutions </span></router-link
      >
      <router-link to="/signatory" class="px-8 pointer" tag="div"
        ><span class=""> Authorized Signatory </span></router-link
      >
      <router-link to="/group_structure" class="px-8 pointer" tag="div"
        ><span class=""> Group Structure </span></router-link
      >
    </v-toolbar>
    <v-row class="ma-4">
      <cert-img-dlg ref="certImgDlg" />
      <note-dlg ref="noteDlg" />
      <WFOENotifyDlg />

      <v-spacer></v-spacer>
     
    </v-row>

    <v-container class="my-3 mx-auto">
      <v-card
        v-for="(controller, idx) in controllers"
        :key="controller.id"
        class="mb-4"
      >
        <v-card-title>
          <h3 class="title text-decoration-underline blue--text">
            Ultimate Beneficial Owner {{ idx + 1 }}
          </h3>

          <v-spacer></v-spacer>
          <span
            class="pointer mr-n3 mt-n8"
            @click.stop="delete_controller(controller)"
            ><v-icon class="red--text">mdi-close-circle</v-icon></span
          >
        </v-card-title>

        <v-form ref="form">
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="">
                <v-col cols="1">a) </v-col>
                <v-col cols="11">Nature of Ultimate Beneficial Owner</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-combobox
                    v-model="controller.nature"
                    :items="natures"
                    item-text="name"
                    item-value="name"
                    @change="update_controller(controller, idx)"
                    outlined
                    :rules="natureRules"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(1)"
              >
                &lt; Note 1 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">b)</v-col>
                <v-col cols="11"> Name of Ultimate Beneficial Owner </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="controller.name"
                    @change="update_controller(controller, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(2)"
              >
                &lt; Note 2 &gt;</span
              >
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">c) </v-col>
                <v-col cols="11"
                  >Company Registration Number/Passport Number of Ultimate Beneficial Owner	</v-col
                >
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    v-model="controller.reg_num"
                    @change="update_controller(controller, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">d) </v-col>
                <v-col cols="11">Country of Ultimate Beneficial Owner</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"></v-col>
                <v-col cols="11">
                  <v-combobox
                    v-model="controller.country"
                    :items="countries"
                    item-text="name"
                    item-value="name"
                    :rules="countryRules"
                    @change="update_controller(controller, idx)"
                    outlined
                  >
                    <template slot="item" slot-scope="data">
                      <country-flag
                        :country="`${data.item.abbr}`"
                        size="normal"
                      />
                      <span class="mx-6">
                        {{ data.item.name }}
                      </span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="px-3 pb-4">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">e) </v-col>
                <v-col cols="11">
                  Registration Certificate/Passport of Ultimate Beneficial Owner 
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <div>
                    <v-file-input
                      v-model="controller.image"
                      clear-icon=""
                      outlined
                      multiple
                      accept="application/pdf"
                      hint="accept pdf files"
                      label="click here to upload registration certificate"
                      :rules="uploadRules2"
                      @change="update_controller(controller, idx)"
                    >
                    </v-file-input>
                  </div>
                  <div class="mx-12 px-12">
                    <v-card
                      v-for="certfile in controller.cert_file"
                      :key="certfile.path"
                      class="mb-4"
                    >
                      <v-img
                        v-if="certfile.file_type === 'image'"
                        :src="certfile.path"
                        @click="show_image(certfile.path)"
                      ></v-img>
                      <div
                        v-if="certfile.file_type === 'pdf'"
                        @click="show_pdf(certfile.path)"
                      >
                        <pdf :src="certfile.path" width="100%" height="100%" />
                      </div>
                      <v-divider></v-divider>
                    </v-card>
                    
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <span
                class="pointer font-italic text-decoration-underline blue--text"
                @click.stop="showNote(3)"
              >
                &lt; Note 3 &gt;</span
              >
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="1"> </v-col>
            <v-col cols="6">
              <v-row no-gutters class="mt-n5">
                <v-col cols="1">f) </v-col>
                <v-col cols="11"
                  >Listed Stock Code & Name of Listed Stock Exchange (Only Listed Company Required)</v-col
                >
              </v-row>
              <v-row no-gutters>
                <v-col cols="1"> </v-col>
                <v-col cols="11">
                  <v-text-field
                    dense
                    outlined
                    :disabled="controller.nature != 'List Company'"
                    v-model="controller.listed_name"
                    @change="update_controller(controller, idx)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
      </v-card>

      <v-row class="ma-4">
        <div>
          <span
            class="text-decoration-underline blue--text pointer"
            @click.stop="addNewController"
            >Add A New Controller</span
          >
        </div>
        <v-spacer></v-spacer>

        <router-link to="/direct_shareholders" tag="div" class="mx-4">
          <span class="text-decoration-underline blue--text pointer">Next</span>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Progressbar from "@/components/Progressbar.vue";
import CertImgDlg from "@/components/CertImgDlg.vue";
import NoteDlg from "@/components/NoteDlg.vue";
import WFOENotifyDlg from "@/components/WFOENotifyDlg.vue";

import pdf from "vue-pdf";

export default {
  components: { Progressbar, CertImgDlg, pdf, NoteDlg, WFOENotifyDlg },

  mounted: function () {
    this.$refs.progress1.bk1 = "/todo2.png";
    this.$refs.progress1.txt1 = "white--text"
  },
  created: function () {
    // Make a request for a user with a given ID
    this.get_country_list();
    this.get_nature_list();
    this.reload_controllers();
  },

  data() {
    return {
      controllers: [],
      countries: [],
      natures: [],

      uploadRules2: [
        (files) => {
          if (files) {
            for (var i = 0; i < files.length; i++) {
              return (
                files[i].size < 10000000 ||
                "file size should be less than 10 MB!"
              );
            }
          } else {
            return true;
          }
        },
      ],

      countryRules: [
        (v) => {
          if (v) {
            return this.country_names.includes(v.name) || "Invald country";
          } else {
            return "Invalid Country";
          }
        },
      ],

      natureRules: [
        (v) => {
          if (v) {
            return this.natures.includes(v) || "Invald nature";
          } else {
            return "Invalid Nature";
          }
        },
      ],
    };
  },

  computed: {
    country_names() {
      return this.countries.map((v) => v.name);
    },
    userRole() {
      return this.$store.getters.userRole;
    },
    fdiStatus: function () {
      return this.$store.getters.fdiStatus;
    },
  },
  methods: {
    get_country_list() {
      console.log("get_country_list");
      let that = this;
      this.$http
        .get("/controllers/get_country_list")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.countries = response.data.countries;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    get_nature_list() {
      console.log("get_nature_list");
      let that = this;
      this.$http
        .get("/controllers/get_nature_list")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.natures = response.data.natures;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },
    reload_controllers() {
      console.log("reload_controllers");
      let that = this;
      this.$http
        .get("/controllers/get_controllers")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.controllers = response.data.controllers;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    addNewController() {
      console.log("add New controller");
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_controllers();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_controllers();
        return;
      }
      let that = this;
      this.$http
        .get("/controllers/add_new_controller")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.reload_controllers();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    update_controller(controller, idx) {
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        this.reload_controllers();
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_controllers();
        return;
      }
      console.log("update_controller..");
      if (controller.nature != "List Company") {
        controller.listed_name = "";
      }
      if (this.$refs.form[idx].validate()) {
        let formData = new FormData();
        let that = this;
        if (controller.image) {
          console.log(controller.image);
          for (var i = 0; i < controller.image.length; i++) {
            formData.append("files[]", controller.image[i]);
          }
        }
        if (controller.id) {
          formData.append("controller_id", controller.id);
        }
        formData.append("name", controller.name);
        formData.append("reg_number", controller.reg_num);
        formData.append("country", controller.country.name);
        formData.append("nature", controller.nature);
        formData.append("listed_name", controller.listed_name);
        console.log(formData);
        this.$http
          .post("/controllers/update_controller", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function () {
            console.log("SUCCESS!");
            that.reload_controllers();
          })
          .catch(function () {
            console.log("FAILURE!!");
          })
          .then(function () {
            console.log("then...");
          });
      } else {
        console.log("form validate fail...");
      }
    },
    delete_controller(controller) {
      if (this.userRole == "staff") {
        this.$EventBus.$emit(
          "toast",
          "Agent cannot modify client FDI Information!"
        );
        return;
      }
      if (this.fdiStatus != "prepare") {
        this.$EventBus.$emit(
          "toast",
          "Cannot modify FDI Information! in " + this.fdiStatus + " status"
        );
        this.reload_controllers();
        return;
      }
      let that = this;
      this.$http
        .post("/controllers/delete_controller/" + controller.id)
        .then(function (response) {
          console.log(response);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.dialog = false;
            that.$EventBus.$emit(
              "toast",
              "Delete Controller " + controller.name + " successfully!"
            );
            that.reload_controllers();
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.certImgDlg.url = url;
      this.$refs.certImgDlg.cert_img = true;
      this.$refs.certImgDlg.file_type = "pdf";
    },

    showNote(idx) {
      if (idx == 1) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Ultimate beneficial owner refers to individuals or PE/VC or foundations or government, which ultimately controls the WFOE directly or indirectly through shares, contracts, trusts or other means. The ultimate beneficial owner should be traced back to listed companies, individuals, government (including government funds), international organizations, state-owned/collective enterprises, etc. ";
      } else if (idx == 2) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "Please confirm the name of ultimate beneficial owner should be identical with its registration certificate/passport.";
      } else if (idx == 3) {
        this.$refs.noteDlg.show_img = false
        this.$refs.noteDlg.text =
          "This registration certificate/passport is no need for notarization and authentication. Please upload the colorful scan copy in A4 (210mm * 297mm) format, with 1:1 proportion in PDF version.";
      }

      this.$refs.noteDlg.dialog = true;
    },
  },
};
</script>


<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #00A3DF;
  border-width: 1px;
  height: 45px;
}
</style>